import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../UserContext.js';
import {
  useUserData,
  getNormalizedUserData,
} from '../../hooks/useUserProfile.js';
import './NavSteps.css';

/**
 * Navigation Steps component displaying circular step indicators for main application flows
 * Uses the same company detection logic as Sidebar component for consistency
 *
 * @param {Object} props Component props
 * @param {String} props.activeStep Current active step identifier
 */
const NavSteps = ({ activeStep }) => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { data: profileData } = useUserData(user?.uid);

  // Use the exact same normalization function as in Sidebar
  const currentUser = getNormalizedUserData(profileData, user);

  // Use the same logic to determine if user has a company
  const hasCompany = !!currentUser.companyId;

  // Use the same text logic as Sidebar
  const companyButtonText = hasCompany ? 'Company Page' : 'Create Company';

  // Use the same navigation logic as Sidebar
  const handleCompanyClick = () => {
    if (hasCompany) {
      navigate(`/companies/${currentUser.companyId}`);
    } else {
      navigate('/companies/create');
    }
  };

  // Define navigation steps
  const steps = [
    {
      id: 'home',
      label: 'Dashboard',
      onClick: () => navigate('/home'),
    },
    {
      id: 'company',
      label: companyButtonText, // Same text as Sidebar uses
      onClick: handleCompanyClick, // Same function as Sidebar uses
    },
    {
      id: 'projects',
      label: 'Projects',
      onClick: () => {
        // Projects require a company, so use same logic as company button
        if (hasCompany) {
          navigate(`/companies/${currentUser.companyId}`);
        } else {
          navigate('/companies/create');
        }
      },
      disabled: !hasCompany, // Disable if no company
    },
  ];

  return (
    <div className="nav-steps-container">
      <div className="nav-steps">
        {steps.map((step) => (
          <div
            key={step.id}
            className={`nav-step ${activeStep === step.id ? 'active' : ''} ${step.disabled ? 'disabled' : ''}`}
            onClick={!step.disabled ? step.onClick : undefined}
            role="button"
            tabIndex={step.disabled ? -1 : 0}
            aria-disabled={step.disabled}
          >
            <div className="step-indicator">
              <span className="step-number"></span>
            </div>
            <span className="step-label">{step.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

NavSteps.propTypes = {
  activeStep: PropTypes.string.isRequired,
};

export default NavSteps;
