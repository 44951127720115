import { useCallback } from 'react'; // Import useCallback from React
import { useQuery, useMutation, useQueryClient } from 'react-query'; // Remove useCallback from here
import axiosInstance from '../config/axios.js';

// Konstanter for query keys - sentralisert
export const USER_QUERY_KEYS = {
  data: (uid) => ['userData', uid],
  list: () => ['usersList'],
};

/**
 * Henter brukerdata fra API
 * @param {string} uid - Brukerens Firebase UID
 * @returns {Promise<Object>} - Brukerens profildata
 */
export const fetchUserData = async (uid) => {
  if (!uid || typeof uid !== 'string' || uid.length < 5) {
    throw new Error('Invalid user ID');
  }

  try {
    const response = await axiosInstance.get(
      `/api/userdata/${encodeURIComponent(uid)}`
    );
    return sanitizeUserData(response.data);
  } catch (error) {
    // Standardisert feilhåndtering
    if (error.response?.status === 404) {
      throw new Error('User not found');
    } else if (error.response?.status === 403) {
      throw new Error('You do not have permission to access this user data');
    } else if (error.response?.status === 401) {
      throw new Error('Authentication required');
    }

    // Re-throw med en mer brukervennlig melding
    throw new Error(
      `Failed to fetch user data: ${error.message || 'Unknown error'}`
    );
  }
};

/**
 * Saniterer og validerer brukerdata
 * @param {Object} userData - Rå brukerdata fra API
 * @returns {Object} - Sanitert brukerdata
 */
const sanitizeUserData = (userData) => {
  if (!userData || typeof userData !== 'object') {
    throw new Error('Invalid user data format');
  }

  // Whitelist tillatte felt
  const allowedFields = [
    'uid',
    'name',
    'email',
    'Company',
    'companyId', // Legg til dette feltet!
    'profilepicurl',
    'phone',
    'role',
    'subscription_status',
    'subscription_name',
    'subscription_end',
    'created',
    'lastLogin',
    'settings',
    // Legg til andre felt du trenger her
  ];

  const sanitized = {};

  allowedFields.forEach((field) => {
    if (userData[field] !== undefined) {
      // XSS-beskyttelse for string-verdier
      if (typeof userData[field] === 'string') {
        sanitized[field] = userData[field]
          .replace(/</g, '&lt;')
          .replace(/>/g, '&gt;');
      } else {
        sanitized[field] = userData[field];
      }
    }
  });

  return sanitized;
};

/**
 * Oppdaterer brukerdata
 * @param {Object} payload - Data som skal oppdateres, inkludert uid
 * @returns {Promise<Object>} - Oppdatert brukerdata
 */
export const updateUserData = async (payload) => {
  if (!payload || !payload.uid) {
    throw new Error('User ID is required for profile updates');
  }

  const { uid, ...updateData } = payload;

  try {
    // Endre post til put for å matche API-konfigurasjon
    const response = await axiosInstance.put(
      `/api/updateprofile/${encodeURIComponent(uid)}`,
      updateData
    );

    return sanitizeUserData(response.data);
  } catch (error) {
    console.error('Profile update error:', error);

    // Bedre feilhåndtering med mer spesifikke meldinger
    if (error.response?.status === 404) {
      throw new Error('Profile update failed: Endpoint not found');
    } else if (error.response?.status === 400) {
      throw new Error(
        `Profile update failed: ${error.response.data || 'Bad request'}`
      );
    } else if (error.response?.status === 401) {
      throw new Error('Profile update failed: Unauthorized');
    } else if (error.response?.status === 500) {
      throw new Error('Profile update failed: Server error');
    }

    throw new Error(
      `Failed to update user data: ${error.message || 'Unknown error'}`
    );
  }
};

/**
 * Hook for å hente brukerdata
 * @param {string} uid - Brukerens Firebase UID
 * @param {Object} options - React Query-opsjoner
 * @returns {Object} - React Query resultat
 */
export const useUserData = (uid, options = {}) => {
  return useQuery(USER_QUERY_KEYS.data(uid), () => fetchUserData(uid), {
    enabled: !!uid,
    staleTime: 1000 * 60 * 20, // 20 minutes (increased from 5 minutes)
    cacheTime: 1000 * 60 * 30, // 30 minutes (increased from 10 minutes)
    retry: 2,
    retryDelay: 3000,
    refetchOnWindowFocus: false, // Prevent automatic refetch when window gets focus
    refetchOnMount: false, // Prevent automatic refetch when component mounts
    ...options,
  });
};

/**
 * Hook for å oppdatere brukerdata
 * @returns {Object} - React Query mutation resultat
 */
export const useUpdateUserData = () => {
  const queryClient = useQueryClient();

  return useMutation(updateUserData, {
    // Når mutasjonen er vellykket, oppdater cachen
    onSuccess: (data, variables) => {
      // Oppdater cachen med de nye dataene
      queryClient.setQueryData(USER_QUERY_KEYS.data(variables.uid), data);
    },
    // Optimistisk oppdatering
    onMutate: async (newData) => {
      // Avbryt pågående spørringer
      await queryClient.cancelQueries(USER_QUERY_KEYS.data(newData.uid));

      // Lagre tidligere data
      const previousData = queryClient.getQueryData(
        USER_QUERY_KEYS.data(newData.uid)
      );

      // Optimistisk oppdatering av cachen
      queryClient.setQueryData(USER_QUERY_KEYS.data(newData.uid), (old) => ({
        ...old,
        ...newData,
      }));

      // Returner kontekst med tidligere data
      return { previousData };
    },
    // Ved feil, rull tilbake til tidligere data
    onError: (err, newData, context) => {
      queryClient.setQueryData(
        USER_QUERY_KEYS.data(newData.uid),
        context.previousData
      );
    },
  });
};

/**
 * Helper for å sette optimistiske oppdateringer direkte
 * @param {Object} queryClient - React Query client
 * @param {string} uid - Brukerens Firebase UID
 * @param {Object} newData - Nye data
 */
export const setOptimisticUserData = (queryClient, uid, newData) => {
  queryClient.setQueryData(USER_QUERY_KEYS.data(uid), (old) => ({
    ...old,
    ...newData,
  }));
};

// Add this function after the existing hooks

/**
 * Hook for å oppdatere brukerdata manuelt
 * @returns {Function} - Funksjon for å oppdatere brukerdata for en gitt UID
 */
export const useRefreshUserData = () => {
  const queryClient = useQueryClient();

  return useCallback(
    async (uid) => {
      if (!uid) return;

      try {
        // Invalider og oppdater cachen
        await queryClient.invalidateQueries(USER_QUERY_KEYS.data(uid));
        // Hent data på nytt
        await queryClient.refetchQueries({
          queryKey: USER_QUERY_KEYS.data(uid),
          exact: true,
        });

        // Legg til kompanyID i tillatte felt hvis det ikke allerede er der
        return true;
      } catch (error) {
        console.error('Error refreshing user data:', error);
        return false;
      }
    },
    [queryClient]
  );
};

/**
 * Returns normalized user data with fallbacks for missing fields
 * @param {Object} userData - User profile data from API
 * @param {Object} firebaseUser - Firebase user object
 * @returns {Object} Normalized user data
 */
export const getNormalizedUserData = (userData, firebaseUser = null) => {
  // If no data at all, return minimal object with Firebase data
  if (!userData && !firebaseUser) return { uid: null };

  // Start with Firebase data if available
  const baseData = firebaseUser
    ? {
        uid: firebaseUser.uid,
        email: firebaseUser.email,
        name: firebaseUser.displayName || '',
        profilepicurl: firebaseUser.photoURL || '',
      }
    : { uid: null };

  // If no DB user data, just return Firebase info
  if (!userData) return baseData;

  // When we have userData from the database, use that (it's more complete)
  return {
    ...baseData,
    ...userData,
    // Ensure Company field is properly preserved with correct casing
    // This is the critical fix - handle both cases to ensure consistency
    Company: userData.Company || userData.company || '',
  };
};

/**
 * Returns a cache-busting image URL
 * @param {string} imageUrl - Original image URL
 * @param {string} fallbackUrl - Fallback image URL
 * @returns {string} URL with cache-busting parameter
 */
export const getCacheBustedImageUrl = (imageUrl, fallbackUrl) => {
  if (!imageUrl || imageUrl === fallbackUrl) return fallbackUrl;
  return `${imageUrl}?t=${Date.now()}`;
};

// Eksporter sanitizeUserData som ikke ble eksportert tidligere
export { sanitizeUserData };

// Add this new hook after the other hooks:

/**
 * Prefetches user profile data with optimized caching for Azure
 * @param {Object} queryClient - React Query Client instance
 * @param {string} uid - User ID to prefetch
 * @returns {Promise<void>}
 */
export const prefetchUserProfile = async (queryClient, uid) => {
  if (!uid) return;

  // Skip prefetching if we already have fresh data
  const existingData = queryClient.getQueryData(USER_QUERY_KEYS.data(uid));
  const dataState = queryClient.getQueryState(USER_QUERY_KEYS.data(uid));

  // Only prefetch if data doesn't exist or is stale
  if (!existingData || (dataState && dataState.isStale)) {
    await queryClient.prefetchQuery(
      USER_QUERY_KEYS.data(uid),
      () => fetchUserData(uid),
      {
        staleTime: 1000 * 60 * 20, // 20 minutes
        cacheTime: 1000 * 60 * 30, // 30 minutes
      }
    );
  }
};
