import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useSearchUsers from '../../../hooks/useSearchUsers.js';
import ProfileCardContainer from './ProfileCardContainer.js';
import LoadingPage from '../../Spinner/LoadingPage.js';
import './SocialCards.css'; // Import our new CSS file
import logger from '../../../utils/logger.js'; // Import logger for debugging
const SearchUsers = ({ setSearchResults }) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const { searchResults, isLoading, handleSearch, clearSearch, currentUserId } =
    useSearchUsers((isFollowing, targetUserId) => {
      logger.log(
        `User ${targetUserId} is now ${isFollowing ? 'followed' : 'unfollowed'}`
      );
      setSearchTerm('');
      setSearchResults([]);
      clearSearch();
    });

  const handleSearchChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    handleSearch(newSearchTerm);
    setSearchResults(searchResults);
  };

  return (
    <div className="following-section">
      <div className="search-input-container">
        <input
          type="text"
          placeholder={t('Connect with other users')}
          value={searchTerm}
          onChange={handleSearchChange}
          disabled={isLoading}
          aria-label={t('search_users')}
        />
        <button type="submit" disabled={isLoading || !searchTerm.trim()}>
          {isLoading ? <LoadingPage /> : t('search')}
        </button>
      </div>

      {isLoading ? (
        <LoadingPage />
      ) : searchResults.length > 0 ? (
        <div className="search-results-container">
          {searchResults.map((searchUser) => (
            <ProfileCardContainer
              key={searchUser.uid}
              user={{
                ...searchUser,
                uid: searchUser.uid,
                profilepicurl: searchUser.profilepicurl || searchUser.photoURL,
                Company: searchUser.Company || searchUser.company,
                isFollowing: searchUser.isFollowing,
              }}
              currentUserId={currentUserId}
              onUpdateFollowing={searchUser.onUpdateFollowing}
              className="search-card" // Add this class for consistent styling
            />
          ))}
        </div>
      ) : searchTerm.trim() ? (
        <div className="no-results">
          <p>{t('no_results_found')}</p>
        </div>
      ) : null}
    </div>
  );
};

SearchUsers.propTypes = {
  setSearchResults: PropTypes.func.isRequired,
};

export default React.memo(SearchUsers);
