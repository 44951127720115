import { useMutation, useQueryClient } from 'react-query';
import axiosInstance from '../config/axios.js';
import { QueryKeys } from '../constants/queryKeys.js';

const postComment = async ({ feed_id, user_uid, comment_text }) => {
  const response = await axiosInstance.post('/api/FeedCommentsAPI', {
    feed_id,
    user_uid,
    comment_text,
  });
  return response.data;
};

export const usePostComment = () => {
  const queryClient = useQueryClient();

  return useMutation(postComment, {
    onMutate: async (newComment) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries([QueryKeys.feed.list(1)]);
      await queryClient.cancelQueries(
        QueryKeys.feed.comments(newComment.feed_id)
      );

      // Snapshot the previous value
      const previousPosts = queryClient.getQueryData(QueryKeys.feed.list(1));
      const previousComments = queryClient.getQueryData(
        QueryKeys.feed.comments(newComment.feed_id)
      );

      const optimisticComment = {
        comment_id: `temp-${Date.now()}`,
        user_uid: newComment.user_uid,
        text: newComment.comment_text,
        commenter_profilepic: queryClient.getQueryData('profilepicurl'),
        username: queryClient.getQueryData('currentUserName'),
        comment_created_at: new Date().toISOString(),
      };

      // Update comments directly in the query cache
      queryClient.setQueryData(
        QueryKeys.feed.comments(newComment.feed_id),
        (old) => {
          if (!old) return { comments: [optimisticComment] };
          return {
            ...old,
            comments: [...old.comments, optimisticComment],
          };
        }
      );

      // Also update the comments in the posts list
      queryClient.setQueryData(QueryKeys.feed.list(1), (old) => {
        if (!old) return old;
        return old.map((post) => {
          if (post.id === newComment.feed_id) {
            return {
              ...post,
              comments: [...(post.comments || []), optimisticComment],
            };
          }
          return post;
        });
      });

      return { previousPosts, previousComments };
    },
    onError: (err, newComment, context) => {
      console.error('Error adding comment:', err);

      // Restore previous data on error
      queryClient.setQueryData(
        QueryKeys.feed.comments(newComment.feed_id),
        context.previousComments
      );

      queryClient.setQueryData(QueryKeys.feed.list(1), context.previousPosts);
    },
    onSettled: (_, __, newComment) => {
      // Invalidate and refetch to ensure data consistency
      queryClient.invalidateQueries(
        QueryKeys.feed.comments(newComment.feed_id),
        { staleTime: 1000 * 60 * 5 } // 5 minutes stale time
      );
      queryClient.invalidateQueries(
        QueryKeys.feed.list(1),
        { staleTime: 1000 * 60 * 5 } // 5 minutes stale time
      );
    },
    // Add configuration options for stale time
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: 1000 * 60 * 30, // 30 minutes
  });
};
