import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  useUserData,
  getNormalizedUserData,
  getCacheBustedImageUrl,
  useRefreshUserData,
} from '../../../hooks/useUserProfile.js';
import { useUser } from '../../../UserContext.js';
import ProfileModal from './ProfileModal.js';
import LoadingPage from '../../Spinner/LoadingPage.js';
import profilepic from '../../../assets/profilepic.png';
import './Sidebar.css';

const Sidebar = ({ onProfileUpdate }) => {
  const [showProfileModal, setShowProfileModal] = useState(false);
  const { user } = useUser();
  const { data: profileData, isLoading } = useUserData(user?.uid);
  const refreshUserData = useRefreshUserData();

  // Get normalized user data from our hook
  const currentUser = getNormalizedUserData(profileData, user);

  // Get cache-busted image URL
  const imageUrl = getCacheBustedImageUrl(
    currentUser.profilepicurl,
    profilepic
  );

  const handleEditProfile = () => {
    setShowProfileModal(true);
  };

  const handleModalClose = async () => {
    if (user?.uid) {
      await refreshUserData(user.uid);
    }
    setShowProfileModal(false);
    onProfileUpdate?.();
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      {!isLoading && currentUser.uid && (
        <div className="sidebar-content">
          <div className="sidebar-profile-card">
            <div className="profile-image-container">
              <img
                src={imageUrl}
                alt={`${currentUser.name}'s profile`}
                className="profile-card-image"
                onError={(e) => {
                  e.target.src = profilepic;
                }}
              />
            </div>

            <div className="profile-details">
              <p className="profile-card-name">{currentUser.name}</p>
              {currentUser.Company && (
                <p className="profile-card-company">{currentUser.Company}</p>
              )}
              {currentUser.email && (
                <a
                  href={`mailto:${currentUser.email}`}
                  className="profile-card-email"
                  title="Get in touch via email"
                >
                  {currentUser.email}
                </a>
              )}
            </div>
            <div className="profile-actions">
              <button className="edit-profile-btn" onClick={handleEditProfile}>
                Edit Profile
              </button>
            </div>
          </div>
        </div>
      )}

      {showProfileModal && (
        <ProfileModal
          onClose={handleModalClose}
          profileData={profileData || currentUser}
        />
      )}
    </>
  );
};

Sidebar.propTypes = {
  onProfileUpdate: PropTypes.func,
};

export default Sidebar;
