const secureSessionStorage = {
  set: (key, value, expiresInMs = 3600000) => {
    // 1 time default
    const item = {
      value,
      expires: Date.now() + expiresInMs,
    };
    sessionStorage.setItem(key, JSON.stringify(item));
  },
  get: (key) => {
    const item = JSON.parse(sessionStorage.getItem(key) || 'null');
    if (!item) return null;
    if (Date.now() > item.expires) {
      sessionStorage.removeItem(key);
      return null;
    }
    return item.value;
  },
  remove: (key) => {
    sessionStorage.removeItem(key);
  },
};

import { getAuth, signOut } from 'firebase/auth';
import axiosInstance from '../config/axios.js';
import logger from '../utils/logger.js';

class AuthService {
  // Henter autentiseringstoken fra Firebase
  static async getToken(forceRefresh = false) {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) return null;
      return await user.getIdToken(forceRefresh);
    } catch (error) {
      logger.error('Auth error:', error);
      return null;
    }
  }

  // Sjekker om bruker er autentisert
  static isAuthenticated() {
    const auth = getAuth();
    return !!auth.currentUser;
  }

  // Henter nåværende bruker
  static getCurrentUser() {
    return getAuth().currentUser;
  }

  // Rydder opp i lokalt lagrede tokens
  static cleanup() {
    secureSessionStorage.remove('firebaseToken');
    secureSessionStorage.remove('token');
    secureSessionStorage.remove('uid');
  }

  // Håndterer utlogging
  static async logout() {
    const auth = getAuth();
    try {
      this.cleanup();
      await signOut(auth);
    } catch (error) {
      logger.error('Logout error:', error);
      throw error;
    }
  }

  // Fornyer token ved behov
  static async refreshToken() {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) return null;

      // First check current token expiration
      const currentToken = await user.getIdToken(false);
      const expirationTime = this.getTokenExpirationTime(currentToken);

      // Only force refresh if token is close to expiring (within 5 minutes)
      const shouldForceRefresh =
        expirationTime && expirationTime - Date.now() < 5 * 60 * 1000;

      // Get a fresh token if needed
      const token = await user.getIdToken(shouldForceRefresh);

      if (token) {
        axiosInstance.defaults.headers.common['Authorization'] =
          `Bearer ${token}`;
        logger.log(
          shouldForceRefresh
            ? 'Token forcefully refreshed'
            : 'Using existing valid token'
        );
      }
      return token;
    } catch (error) {
      logger.error('Token refresh error:', error);
      return null;
    }
  }

  // Erstatt localStorage-kall med secure session storage
  static registerUserCreation(uid) {
    secureSessionStorage.set('userCreationInProgress', uid, 600000); // 10 min utløpstid
    secureSessionStorage.set('userCreationStartTime', Date.now(), 600000);
  }

  static isUserCreationInProgress() {
    return !!secureSessionStorage.get('userCreationInProgress');
  }

  static getUserCreationUid() {
    return secureSessionStorage.get('userCreationInProgress');
  }

  static getUserCreationStartTime() {
    return parseInt(
      secureSessionStorage.get('userCreationStartTime') || '0',
      10
    );
  }

  static markUserCreationComplete(uid) {
    // Lagre UID for fremtidig referanse (for wasUserRecentlyCreated-metoden)
    secureSessionStorage.set('userCreationComplete', uid, 600000); // 10 min utløpstid
    secureSessionStorage.set('userCreationCompleteTime', Date.now(), 600000);

    // Fjern "in progress" markeringer
    secureSessionStorage.remove('userCreationInProgress');
    secureSessionStorage.remove('userCreationStartTime');
  }

  static wasUserRecentlyCreated(uid, timeWindowMs = 30000) {
    const completedUid = secureSessionStorage.get('userCreationComplete');
    const completionTime = parseInt(
      secureSessionStorage.get('userCreationCompleteTime') || '0'
    );

    // Check if this user was recently created and marked as complete
    return completedUid === uid && Date.now() - completionTime < timeWindowMs;
  }

  /**
   * Henter brukerdetaljer fra API
   * @param {string} uid Firebase bruker-ID
   * @returns {Promise<Object>} Brukerdetaljer
   */
  static async fetchUserDetails(uid) {
    try {
      const response = await axiosInstance.get(`/api/userdata/${uid}`);
      return response.data;
    } catch (error) {
      logger.error('Failed to fetch user details:', error);
      throw error;
    }
  }

  // Add this method to your AuthService class
  static getTokenExpirationTime(token) {
    if (!token) return null;

    try {
      // Split the token and get the middle part (payload)
      const payload = token.split('.')[1];
      const decoded = JSON.parse(atob(payload));
      return decoded.exp * 1000; // Convert to milliseconds
    } catch (error) {
      logger.error('Error decoding token:', error);
      return null;
    }
  }
}

// Kjører cleanup ved oppstart
AuthService.cleanup();

export default AuthService;
