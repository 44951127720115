import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useUpdateUserData } from '../../../hooks/useUserProfile.js';
import { useProfilePicture } from '../../../hooks/useProfilePicture.js';
import { useUser } from '../../../UserContext.js';
import profilepic from '../../../assets/profilepic.png';
import './ProfileModal.css';
import logger from '../../../utils/logger.js';

const ProfileModal = ({ onClose, profileData }) => {
  const { t } = useTranslation();
  const { currentUserId } = useUser();
  const updateProfile = useUpdateUserData();
  const uploadProfilePicture = useProfilePicture();

  // Form state
  const [formData, setFormData] = useState({
    name: '',
    profilepicurl: '',
  });

  const [profileImageFile, setProfileImageFile] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [uploadingImage, setUploadingImage] = useState(false);
  const [error, setError] = useState('');

  // Populate form with existing data when available
  useEffect(() => {
    if (profileData) {
      setFormData({
        name: profileData.name || '',
        profilepicurl: profileData.profilepicurl || '',
      });
      setProfileImageUrl(profileData.profilepicurl || '');
    }
  }, [profileData]);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setError(''); // Clear error when user makes changes
  };

  // Handle profile image selection
  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      // Validate file size (max 5MB)
      if (file.size > 5 * 1024 * 1024) {
        setError(t('image_too_large', 'Image file is too large (max 5MB)'));
        return;
      }

      setProfileImageFile(file);
      setError(''); // Clear any errors

      // Preview the image
      const reader = new FileReader();
      reader.onload = (event) => {
        setProfileImageUrl(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    // Validation
    if (!formData.name.trim()) {
      setError(t('name_required', 'Name is required'));
      return;
    }

    try {
      // First upload the profile picture if a new one was selected
      let finalProfileUrl = profileImageUrl;
      if (profileImageFile) {
        setUploadingImage(true);
        try {
          finalProfileUrl = await uploadProfilePicture.mutateAsync({
            file: profileImageFile,
            userId: currentUserId,
          });

          setProfileImageUrl(finalProfileUrl);
        } catch (error) {
          console.error('Image upload failed:', error);
          setError(t('image_upload_failed', 'Failed to upload image'));
          setUploadingImage(false);
          return;
        }
        setUploadingImage(false);
      }

      logger.log('Starting profile update with data:', {
        userId: '[REDACTED]',
        name: formData.name,
        hasProfileImage: !!finalProfileUrl,
      });

      const updatedData = {
        name: formData.name.trim(),
        profilepicurl: finalProfileUrl,
      };

      await updateProfile.mutateAsync({
        uid: currentUserId,
        ...updatedData,
      });

      toast.success(
        t('profile_updated_success', 'Profile updated successfully')
      );
      handleClose();
    } catch (error) {
      console.error('Profile update error:', error);
      setError(
        error.message || t('profile_update_error', 'Failed to update profile')
      );
    }
  };

  const handleClose = () => {
    const overlay = document.querySelector('.profile-edit-overlay');
    if (overlay) {
      overlay.classList.add('closing');
      // Reduce timeout to make it feel more responsive
      setTimeout(() => {
        onClose();
      }, 200); // Changed from 300ms to 200ms for faster response
    } else {
      onClose();
    }
  };

  return (
    <div className="profile-edit-overlay">
      <div className="create-company-container profile-edit-content">
        <h2 className="modal-title">{t('edit_profile')}</h2>

        {error && (
          <div className="alert alert-danger">
            <i className="fas fa-exclamation-circle"></i> {error}
          </div>
        )}

        <div className="profile-preview">
          {profileImageUrl ? (
            <img
              src={profileImageUrl}
              alt={t('profile_picture')}
              className="profile-preview-image"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = profilepic;
              }}
            />
          ) : (
            <div className="profile-preview-placeholder">
              {formData.name.charAt(0) || '?'}
            </div>
          )}
        </div>

        <form onSubmit={handleSubmit} className="company-form">
          <div className="form-group">
            <label htmlFor="name">{t('name')} *</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              placeholder={t('enter_your_name')}
              className={`form-control ${!formData.name.trim() && error ? 'is-invalid' : ''}`}
            />
          </div>

          <div className="form-group file-upload">
            <label htmlFor="profileImage">{t('profile_picture')}</label>
            <div className="file-input-wrapper">
              <button
                type="button"
                className="file-input-button"
                onClick={() => document.getElementById('profileImage').click()}
              >
                <i className="fas fa-upload"></i> {t('choose_file')}
              </button>
              <input
                type="file"
                id="profileImage"
                name="profileImage"
                accept="image/*"
                onChange={handleImageChange}
                className="file-input"
              />
              <span className="file-name">
                {profileImageFile ? profileImageFile.name : t('no_file_chosen')}
              </span>
            </div>
            <span className="help-text">
              {t('max_file_size', 'Maximum file size: 5MB')}
            </span>
          </div>

          {uploadingImage && (
            <div className="upload-progress">
              <div className="progress-bar">
                <div className="progress-bar-inner"></div>
              </div>
              <p>{t('uploading_image')}</p>
            </div>
          )}

          <div className="form-actions">
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={handleClose}
            >
              <i className="fas fa-times"></i> {t('cancel')}
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={uploadingImage || updateProfile.isLoading}
            >
              {updateProfile.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> {t('saving')}
                </>
              ) : (
                <>
                  <i className="fas fa-save"></i> {t('Update')}
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

ProfileModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  profileData: PropTypes.shape({
    name: PropTypes.string,
    profilepicurl: PropTypes.string,
    uid: PropTypes.string,
  }).isRequired,
};

export default ProfileModal;
