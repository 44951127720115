import React from 'react';
import { useUser } from '../../UserContext.js';
import './SubscriptionPlans.css';
import SEOHelmet from '../SEO/SEOHelmet.js';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Legg til denne importen
import logger from '../../utils/logger.js'; // Importer logger

const SubscriptionPlans = () => {
  const { t } = useTranslation(); // Legg til useTranslation-hook
  useUser();
  const navigate = useNavigate();

  // ============= ORIGINAL LEMONSQUEEZY CHECKOUT LINKS (COMMENTED OUT) =============
  /*
  const checkoutLinks = {
    publicuser:
      'https://plancoo.lemonsqueezy.com/buy/a472a8a2-345f-4a5d-8482-0036003d99ab',
    business:
      'https://plancoo.lemonsqueezy.com/buy/cbc6934c-09d9-4c6f-8a62-767318c52f30',
    enterprise: {
      contact: 'mailto:contact@plancoo.com?subject=Enterprise%20Plan%20Inquiry',
    },
  };
  */

  // Temporary empty object to prevent errors
  const checkoutLinks = {
    enterprise: {
      contact: 'mailto:contact@plancoo.com?subject=Enterprise%20Plan%20Inquiry',
    },
  };

  // ============= NEWSLETTER TOAST FUNCTIONALITY =============
  // Funksjon for å vise toast og omdirigere til landingssiden
  const showNewsletterToast = (planName) => {
    toast.info(
      <div>
        <p>
          <strong>{t('midtext_heading')}</strong>
        </p>
        <p>{t('subscription_development_message', { planName: planName })}</p>
        <button
          onClick={() => {
            toast.dismiss();
            navigate('/');
          }}
          className="toast-button"
        >
          {t('ok')}
        </button>
      </div>,
      {
        position: 'top-center',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
      }
    );
  };

  // ============= ORIGINAL CHECKOUT HANDLER (COMMENTED OUT) =============
  /*
  const handleCheckout = (plan) => {
    logger.log(`Checkout initiated for plan: ${plan}`);

    if (plan === 'enterprise') {
      logger.log('Enterprise plan selected, redirecting to contact');
      window.location.href = checkoutLinks.enterprise.contact;
      return;
    }

    const checkoutUrl = checkoutLinks[plan];
    logger.log(`Checkout URL: ${checkoutUrl}`);

    if (user?.email) {
      const url = new URL(checkoutUrl);
      url.searchParams.append('checkout[email]', user.email);
      window.location.href = url.toString();
    } else {
      window.location.href = checkoutUrl;
    }
  };
  */

  // ============= NEW TEMPORARY CHECKOUT HANDLER =============
  const handleCheckout = (plan) => {
    logger.log(`Newsletter signup promoted for plan: ${plan}`);

    // Fortsatt behandle enterprise-planen som tidligere
    if (plan === 'enterprise') {
      logger.log('Enterprise plan selected, redirecting to contact');
      window.location.href = checkoutLinks.enterprise.contact;
      return;
    }

    // For andre planer, vis nyhetsbrevmelding
    const planDisplayName =
      plan === 'publicuser'
        ? t('public_user')
        : plan === 'business'
          ? t('business')
          : t('enterprise');

    showNewsletterToast(planDisplayName);
  };

  // Bruk oversettelser for plandata
  const plans = [
    {
      name: t('public_user'),
      monthlyPrice: '299',
      features: [
        t('plan_feature_single_user'),
        t('plan_feature_unlimited_projects'),
        t('plan_feature_gantt'),
        t('plan_feature_network'),
        t('plan_feature_collaborate'),
      ],
      isPopular: false,
      customPricing: false,
    },
    {
      name: t('business'),
      monthlyPrice: '899',
      features: [
        t('plan_feature_min_users'),
        t('plan_feature_standard'),
        '✨ ' + t('plan_feature_company_feed'),
        '🔗 ' + t('plan_feature_magic_link'),
      ],
      isPopular: true,
      customPricing: false,
    },
    {
      name: t('enterprise'),
      monthlyPrice: t('contact_for_price'),
      features: [
        t('plan_feature_teams'),
        t('plan_feature_flex_users'),
        t('plan_feature_business_included'),
        t('plan_feature_dedicated'),
      ],
      isPopular: false,
      customPricing: true,
    },
  ];

  return (
    <div className="subscription-plans-container">
      <SEOHelmet
        title={`${t('subscriptions_and_pricing')} - PlanCoo`}
        description={t('subscription_seo_description')}
        url="https://www.plancoo.com/subscriptions"
      />

      <p className="subscription-subtitle">
        {t('choose_subscription')} <strong>PlanCoo {t('subscription')}</strong>{' '}
        {t('that_meets_requirements')}. {t('payments_processed')}{' '}
        <strong>{t('securely')}</strong> {t('through_provider')}{' '}
        <strong>{t('global_privacy')}</strong> {t('and')}
        <strong> {t('security_regulations')}</strong>.
      </p>
      <div className="plans-grid">
        {plans.map((plan) => {
          const isEnterprise = plan.name === t('enterprise');

          return (
            <div
              className={`plan-card ${plan.isPopular ? 'popular-plan' : ''} ${isEnterprise ? 'enterprise-plan' : ''}`}
              key={plan.name}
            >
              {plan.isPopular && (
                <div className="popular-badge">{t('most_popular')}</div>
              )}

              <h2 className="plan-name">{plan.name}</h2>
              <div className="plan-price">
                {!plan.customPricing ? (
                  <>
                    <span className="price-amount">kr {plan.monthlyPrice}</span>
                    <span className="price-period">/{t('month')}</span>
                  </>
                ) : (
                  <span className="custom-price">{plan.monthlyPrice}</span>
                )}
              </div>

              <ul className="plan-features">
                {plan.features.map((feature, index) => (
                  <li
                    key={index}
                    className={
                      feature.includes('✨') || feature.includes('🔗')
                        ? 'highlighted-feature'
                        : ''
                    }
                  >
                    {feature.replace("'", '&apos;')}
                  </li>
                ))}
              </ul>

              <button
                className="subscribe-button"
                onClick={() =>
                  handleCheckout(plan.name.toLowerCase().replace(' ', ''))
                }
              >
                {plan.customPricing
                  ? t('contact_us')
                  : `${t('choose')} ${plan.name}`}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SubscriptionPlans;
