import React, { useEffect } from 'react';
import AuthService from '../../services/authService.js';
import logger from '../../utils/logger.js';
const CleanupTokens = () => {
  useEffect(() => {
    // Clean up tokens when component mounts
    AuthService.cleanup();
    if (process.env.NODE_ENV === 'development') {
      logger.log('Auth tokens cleaned up');
    }
  }, []);

  return (
    <div className="cleanup-container">
      <h2>Session Cleanup</h2>
      <p>Your session tokens have been cleaned up.</p>
    </div>
  );
};

export default CleanupTokens;
