import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './SignUp.css';
import GoogleBtn from '../Buttons/Auth/GoogleBtn.js';
import MicrosoftBtn from '../Buttons/Auth/MicrosoftBtn.js';
import { useUser } from '../../UserContext.js';
import { useFirebaseAuth } from '../../hooks/useFirebaseAuth.js';
import MagicLinkBtn from '../Buttons/Auth/MagicLinkBtn.js';
import SEOHelmet from '../SEO/SEOHelmet.js';
import DOMPurify from 'dompurify'; // For XSS-beskyttelse
import { toast } from 'react-toastify'; // For toast notifications
import logger from '../../utils/logger.js';

const SignUp = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  // Sikker melding-setter som renser innholdet
  const setSecureMessage = (msg) => {
    setMessage(DOMPurify.sanitize(msg));
  };

  const { handleGoogleSignIn, handleEmailSignIn, handlePasswordReset } =
    useFirebaseAuth();

  useEffect(() => {
    if (user) {
      navigate('/Home');
    }
  }, [user, navigate]);

  // Input validering
  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };

  const onGoogleSignIn = async () => {
    try {
      setLoading(true);
      setSecureMessage(t('signing_in_with_google'));

      // Sjekk om vi er på mobil
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

      if (isMobile) {
        // Vis en advarsel om potensielle popup-problemer på mobil
        toast.info(t('allow_popups_mobile'), {
          autoClose: 7000,
          position: 'top-center',
        });
      }

      await handleGoogleSignIn(setSecureMessage);
    } catch (error) {
      logger.error('Google sign-in failed:', error);
      setSecureMessage(t('auth_generic_error'));
      setLoading(false);
    }
  };

  const onEmailSignIn = async (e) => {
    e.preventDefault();

    // Validering
    if (!email || !password) {
      setSecureMessage(t('input_required'));
      return;
    }

    if (!validateEmail(email)) {
      setSecureMessage(t('auth_generic_error')); // Bruk generisk melding for å hindre informasjonslekkasje
      return;
    }

    setLoading(true);
    try {
      await handleEmailSignIn(email, password, setSecureMessage);
    } catch (error) {
      logger.error('Email sign-in error:', error);
      // Feilhåndtering skjer i hooken
    } finally {
      setLoading(false);
    }
  };

  const onPasswordReset = () => {
    if (!email) {
      setSecureMessage(t('password_reset_required_email'));
      return;
    }

    if (!validateEmail(email)) {
      setSecureMessage(t('auth_generic_error')); // Bruk generisk melding
      return;
    }

    setLoading(true);
    handlePasswordReset(email, setSecureMessage).finally(() => {
      setLoading(false);
    });
  };

  const onMicrosoftSignIn = () => {
    setSecureMessage('Microsoft-innlogging er ikke implementert ennå');
  };

  const onMagicLinkSignIn = () => {
    setSecureMessage('Magic Link er ikke implementert ennå');
  };

  return (
    <div className="signupbackground">
      <SEOHelmet
        title={`${t('login')} - PlanCoo`}
        description={t('login_secure_passwordless')}
        url="https://www.plancoo.com/signup"
      />

      <div className="login-description-header">
        <p>{t('login_secure_passwordless')}</p>
      </div>

      <div className="signUp">
        <h2>{t('login')}</h2>

        <div className="socioalSignIn">
          <GoogleBtn onClick={onGoogleSignIn} disabled={loading}>
            {loading ? t('login_processing') : t('login_with_google')}
          </GoogleBtn>
          <MicrosoftBtn onClick={onMicrosoftSignIn} disabled={loading}>
            {loading ? t('login_processing') : t('login_with_microsoft')}
          </MicrosoftBtn>
        </div>

        <MagicLinkBtn onClick={onMagicLinkSignIn} disabled={loading} />

        <div className="divider">
          <span>{t('or')}</span>
        </div>

        <form onSubmit={onEmailSignIn}>
          <input
            type="email"
            placeholder={t('enter_email')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <div className="password-container">
            <input
              type="password"
              placeholder={t('enter_password')}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              minLength="8"
              aria-describedby="password-requirements"
            />
            <button
              type="button"
              className="forgot-password-btn"
              onClick={onPasswordReset}
              disabled={loading}
            >
              {t('forgot_password')}
            </button>
          </div>
          <div className="password-requirements" id="password-requirements">
            {/* Password requirements message can be added here */}
          </div>
          <button type="submit" className="btn btn-signup" disabled={loading}>
            {loading ? t('login_processing') : t('login_with_password')}
          </button>
        </form>

        {message && (
          <p
            className="messageText"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
      </div>
    </div>
  );
};

export default SignUp;
