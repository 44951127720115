import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useUser } from '../../UserContext.js';
import { useCompany } from '../../hooks/useCompanies.js';
import ProjectGallery from '../Projects/ProjectGallery.js';
import CompanyProfileEdit from './CompanyProfileEdit.js';
import CompanyLayout from './CompanyLayout.js';
import Spinner from '../Spinner/Spinner.js';
import { FaEdit, FaBuilding, FaGlobe } from 'react-icons/fa';
import './CompanyProfile.css';

// Helper function to parse social media links
const parseSocialLinks = (socialMediaLinksString) => {
  if (!socialMediaLinksString) return [];

  try {
    // Try to parse as JSON if it's a string
    if (typeof socialMediaLinksString === 'string') {
      const parsed = JSON.parse(socialMediaLinksString);
      return Array.isArray(parsed) ? parsed : [];
    }
    // If it's already an object/array, return it directly
    if (Array.isArray(socialMediaLinksString)) {
      return socialMediaLinksString;
    }
    // Fallback: return empty array
    return [];
  } catch (error) {
    console.error('Error parsing social media links:', error);
    return [];
  }
};

const ensureHttpPrefix = (url) => {
  if (!url) return '#';
  return url.match(/^https?:\/\//) ? url : `https://${url}`;
};

const CompanyProfileView = () => {
  const { id } = useParams();
  const { user } = useUser();
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // Fetch company data
  const {
    data: company,
    isLoading: isLoadingCompany,
    error: companyError,
    refetch: refetchCompany,
  } = useCompany(parseInt(id, 10));

  // Check if user is owner or admin of the company
  const isCompanyAdmin =
    user &&
    company &&
    (company.ownerUid === user.uid ||
      company.members?.some(
        (member) => member.userUid === user.uid && member.role === 'ADMIN'
      ));

  // Handle cancel edit
  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  // Prefetch next page of projects when component mounts
  useEffect(() => {
    if (id && user?.uid) {
      // Your prefetch logic here if needed
    }
  }, [id, queryClient, user?.uid, company?.ownerUid]);

  // Show loading screen
  if (isLoadingCompany) {
    return <Spinner text="Loading company data..." />;
  }

  // Show error message if there is an error
  if (companyError) {
    return (
      <div className="alert alert-danger">
        <h3>Error loading company</h3>
        <p>{companyError.message || 'Unknown error'}</p>
        <button className="btn btn-primary" onClick={() => refetchCompany()}>
          Try again
        </button>
        <button
          className="btn btn-secondary ms-2"
          onClick={() => navigate('/companies')}
        >
          Back to companies
        </button>
      </div>
    );
  }

  // If there is no company data
  if (!company) {
    return (
      <div className="alert alert-warning">
        <h3>Company not found</h3>
        <p>
          The company with ID {id} was not found or you don&apos;t have access.
        </p>
        <button
          className="btn btn-secondary"
          onClick={() => navigate('/companies')}
        >
          Back to companies
        </button>
      </div>
    );
  }

  // Show edit mode
  if (isEditing) {
    return (
      <CompanyProfileEdit
        company={company}
        onCancel={handleCancelEdit}
        onSuccess={() => {
          setIsEditing(false);
          refetchCompany();
        }}
      />
    );
  }

  // Updated main view layout
  return (
    <CompanyLayout>
      <div className="company-profile">
        {/* Company header */}
        <section className="company-header-section">
          <div className="company-header-container">
            <div className="company-logo-section">
              <div className="company-logo-container">
                {company.Logo ? (
                  <img
                    src={company.Logo}
                    alt={`${company.name} logo`}
                    className="company-logo-image"
                    onError={(e) => {
                      e.target.style.display = 'none';
                    }}
                  />
                ) : (
                  <div className="company-logo-placeholder">
                    {company.name.charAt(0).toUpperCase()}
                  </div>
                )}
              </div>

              {/* Edit button moved BELOW the logo container */}
              {isCompanyAdmin && (
                <button
                  className="btn-edit-company-below"
                  onClick={() => setIsEditing(true)}
                >
                  <FaEdit /> Edit Company
                </button>
              )}
            </div>

            <div className="company-details-container">
              <div className="company-header-top">
                <h1 className="company-name">{company.name}</h1>
              </div>

              <div className="company-category">
                <FaBuilding className="category-icon" />{' '}
                {company.BusinessArea || 'Not categorized'}
              </div>

              <div className="company-description-section">
                <div className="company-description-header">About:</div>
                <div className="company-description">
                  {company.About ? (
                    <p>{company.About}</p>
                  ) : (
                    <p className="text-muted">No description available.</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="company-section-divider"></div>

          <div className="company-links-container">
            <div className="company-links-inner">
              {company.Website && (
                <a
                  href={ensureHttpPrefix(company.Website)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="company-website-link"
                >
                  <FaGlobe /> {company.Website.replace(/^https?:\/\//, '')}
                </a>
              )}

              {company.SocialMediaLinks &&
                parseSocialLinks(company.SocialMediaLinks).length > 0 && (
                  <div className="social-links-wrapper">
                    <div className="social-links">
                      {parseSocialLinks(company.SocialMediaLinks).map(
                        (link, index) => (
                          <a
                            key={index}
                            href={ensureHttpPrefix(link.url)}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="social-link"
                          >
                            {link.name}
                          </a>
                        )
                      )}
                    </div>
                  </div>
                )}
            </div>
          </div>
        </section>

        <div style={{ height: '1.5rem' }}></div>

        {/* Project Gallery */}
        <section className="projects-section">
          <div className="company-section-divider"></div>

          {/* Use ProjectGallery component */}
          <ProjectGallery
            companyId={parseInt(id, 10)}
            canManage={isCompanyAdmin}
          />
        </section>
      </div>
    </CompanyLayout>
  );
};

export default CompanyProfileView;
