import './firebaseConfig.js';
import { auth } from './firebaseConfig.js';
import React, { useEffect, useState } from 'react';
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import PropTypes from 'prop-types';
import i18n from './components/Language/i18n.js';
import SignUp from './components/Login/SignUp.js';
import MyNavbar from './components/Navbar/NavBar.js';
import GanttChart from './components/Gantt/GanttChart.js';
import MobileProjects from './components/Gantt/MobileProjects.js';
import { UserProvider, useUser } from './UserContext.js';
import Home from './components/Homepage/Home.js';
import CompanyPage from './components/Company/CompanyPage.js';
import { QueryClient, QueryClientProvider, useQueryClient } from 'react-query';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LandingPage from './components/LandingPage/LandingPage.js';
import { fetchUserDetails } from './services/userService.js';
import LoadingPage from './components/Spinner/LoadingPage.js';
import PaymentSuccess from './components/LemonSqueezy/PaymentSuccess.js';
import PaymentCancelled from './components/LemonSqueezy/PaymentCancelled.js';
import SubscriptionPlans from './components/LemonSqueezy/SubscriptionPlans.js';
import SubscriptionManager from './components/LemonSqueezy/SubscriptionManager.js';
import DevPasswordGate from './DevPasswordGate.js';
import LegalDocument from './components/Legal/LegalDocument.js';
import WebsiteSchema from './components/JsonLd/WebsiteSchema.js';
import CleanupTokens from './components/auth/CleanupTokens.js';
import logger from './utils/logger.js';
import CompanyProfileView from './components/Company/CompanyProfileView.js';
import CreateCompanyForm from './components/Company/CreateCompanyForm.js';
import CompanyProfileEdit from './components/Company/CompanyProfileEdit.js';
import { prefetchUserProfile } from './hooks/useUserProfile.js';

// Configure QueryClient with optimized Azure settings
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
      retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 30000), // Exponential backoff
      staleTime: 1000 * 60 * 5, // 5 minutes default stale time
    },
  },
});

// RequireAuth component remains the same
const RequireAuth = ({ children }) => {
  const { user } = useUser();
  const [isVerifying, setIsVerifying] = useState(true);

  // Same implementation...
  useEffect(() => {
    const checkAuth = async () => {
      try {
        await auth.currentUser?.reload();
        setIsVerifying(false);
      } catch (error) {
        logger.error('Auth verification failed:', error);
        setIsVerifying(false);
      }
    };

    checkAuth();
  }, []);

  if (isVerifying) {
    return <LoadingPage />;
  }

  if (!user || !auth.currentUser) {
    return <Navigate to="/SignUp" />;
  }

  return children;
};

RequireAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

// The main App component now just sets up providers
function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <UserProvider>
          <AppContent />
        </UserProvider>
      </QueryClientProvider>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

// Create a new component for the app content that can safely use hooks
function AppContent() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient(); // Now this is used safely inside the provider

  // Handle DOM ready state
  useEffect(() => {
    const handleDOMLoad = () => {
      const rootElement = document.getElementById('root');
      if (rootElement) {
        rootElement.classList.add('app-loaded');
      }
    };

    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', handleDOMLoad);
    } else {
      handleDOMLoad();
    }

    return () => {
      document.removeEventListener('DOMContentLoaded', handleDOMLoad);
    };
  }, []);

  // Token cleanup effect
  useEffect(() => {
    // Clean up any existing tokens across all domains
    localStorage.removeItem('firebaseToken');
    localStorage.removeItem('token');
    localStorage.removeItem('uid');

    // Replace the iframe approach with this
    try {
      // Use the current origin rather than an environment variable
      const cleanupUrl = `${window.location.origin}/cleanup-tokens`;

      // Use fetch approach instead of iframe to avoid COOP issues
      fetch(cleanupUrl, {
        method: 'GET',
        mode: 'no-cors',
        credentials: 'include',
      }).catch((error) => {
        logger.error('Failed to fetch cleanup URL', error);
      });
    } catch (error) {
      logger.error('Failed to cleanup tokens', error);
    }
  }, []);

  // Auth state effect
  useEffect(() => {
    let isInitialMount = true;

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (isInitialMount) {
        isInitialMount = false;
        setLoading(false);
        if (user) {
          setUser(user);
          // Bare sett brukerdata uten toast på initial mount
          try {
            const userDetails = await fetchUserDetails(user.uid);
            updateUserQueries(userDetails, queryClient);
          } catch (error) {
            logger.error('Failed to fetch user details:', error);
          }
        }
        return;
      }

      if (user) {
        setUser(user);
        try {
          const userDetails = await fetchUserDetails(user.uid);
          updateUserQueries(userDetails, queryClient);
        } catch (error) {
          logger.error('Failed to fetch user details:', error);
          toast.error('Failed to fetch user data');
        }
      } else if (!isInitialMount) {
        // Vis bare logout melding hvis det ikke er initial mount
        setUser(null);
        clearUserQueries(queryClient);
      }
    });

    return () => unsubscribe();
  }, [queryClient]);

  // HTML language effect
  useEffect(() => {
    const updateHtmlLang = () => {
      const currentLang = i18n.language;
      document.documentElement.lang = currentLang;
    };

    updateHtmlLang();
    i18n.on('languageChanged', updateHtmlLang);

    return () => {
      i18n.off('languageChanged', updateHtmlLang);
    };
  }, []);

  // Auth callback check effect
  useEffect(() => {
    // Sjekk om vi kommer fra en auth-redirect
    const isAuthCallback = window.location.pathname.includes('/__/auth');

    if (isAuthCallback && user) {
      // Brukeren er autentisert og vi er på en auth-callback URL
      navigate('/Home');
    }
  }, [user, navigate]);

  // Prefetch user data when navigating between routes
  useEffect(() => {
    if (user?.uid) {
      prefetchUserProfile(queryClient, user.uid);
    }
  }, [location.pathname, queryClient, user?.uid]);

  // Helper functions
  const updateUserQueries = (userDetails, queryClient) => {
    const {
      customerId,
      name,
      Company,
      subscription_status,
      subscription_name,
      profilepicurl,
    } = userDetails;

    queryClient.setQueryData('customerId', customerId);
    queryClient.setQueryData('currentUserName', name);
    queryClient.setQueryData('currentUserCompany', Company);
    queryClient.setQueryData('subscriptionStatus', subscription_status);
    queryClient.setQueryData('subscriptionName', subscription_name);
    queryClient.setQueryData('profilepicurl', profilepicurl);
  };

  const clearUserQueries = (queryClient) => {
    queryClient.removeQueries('currentUserId');
    queryClient.removeQueries('currentUserEmail');
    queryClient.removeQueries('customerId');
  };

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <>
      {/* Navbar - always visible */}
      <MyNavbar user={user} />
      <WebsiteSchema />

      {/* Routes component for all routes */}
      <Routes>
        {/* Public routes */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/LandingPage" element={<LandingPage />} />
        <Route
          path="/terms"
          element={
            <LegalDocument
              documentPath="/legal/TermServ.md"
              title="Terms of Service for PlanCoo"
            />
          }
        />
        <Route
          path="/privacy"
          element={
            <LegalDocument
              documentPath="/legal/PrivSta.md"
              title="Privacy Policy for PlanCoo"
            />
          }
        />
        <Route
          path="/dpa"
          element={
            <LegalDocument
              documentPath="/legal/Dpa.md"
              title="Data Processing Agreement for PlanCoo"
            />
          }
        />
        <Route path="/cleanup-tokens" element={<CleanupTokens />} />

        {/* Auth-protected routes with DevPasswordGate */}
        <Route
          path="/SignUp"
          element={
            <DevPasswordGate>
              {user ? <Navigate to="/Home" /> : <SignUp />}
            </DevPasswordGate>
          }
        />
        <Route
          path="/signup"
          element={
            <DevPasswordGate>
              {user ? <Navigate to="/Home" /> : <SignUp />}
            </DevPasswordGate>
          }
        />
        <Route
          path="/subscriptions"
          element={
            <DevPasswordGate>
              <SubscriptionPlans />
            </DevPasswordGate>
          }
        />
        <Route
          path="/payment-success"
          element={
            <DevPasswordGate>
              <PaymentSuccess />
            </DevPasswordGate>
          }
        />
        <Route
          path="/payment-cancelled"
          element={
            <DevPasswordGate>
              <PaymentCancelled />
            </DevPasswordGate>
          }
        />

        {/* User authenticated routes */}
        <Route
          path="/Home"
          element={
            <DevPasswordGate>
              <RequireAuth>
                <Home />
              </RequireAuth>
            </DevPasswordGate>
          }
        />
        <Route
          path="/home"
          element={
            <DevPasswordGate>
              <RequireAuth>
                <Home />
              </RequireAuth>
            </DevPasswordGate>
          }
        />
        <Route
          path="/Gantt"
          element={
            <DevPasswordGate>
              <RequireAuth>
                <GanttChart />
              </RequireAuth>
            </DevPasswordGate>
          }
        />
        <Route
          path="/company"
          element={
            <DevPasswordGate>
              <RequireAuth>
                <CompanyPage />
              </RequireAuth>
            </DevPasswordGate>
          }
        />
        <Route
          path="/mobile-projects"
          element={
            <DevPasswordGate>
              <RequireAuth>
                <MobileProjects />
              </RequireAuth>
            </DevPasswordGate>
          }
        />
        <Route
          path="/SubscriptionManager"
          element={
            <DevPasswordGate>
              <RequireAuth>
                <SubscriptionManager />
              </RequireAuth>
            </DevPasswordGate>
          }
        />
        <Route path="/company/profile/:id" element={<CompanyProfileView />} />
        <Route path="/companies/create" element={<CreateCompanyForm />} />
        <Route path="/companies/:id" element={<CompanyProfileView />} />
        <Route path="/companies/:id/edit" element={<CompanyProfileEdit />} />
        <Route
          path="/projects/:projectId/mobile"
          element={<MobileProjects />}
        />
        <Route path="/projects/:projectId/gantt" element={<GanttChart />} />
      </Routes>
    </>
  );
}

export default App;
