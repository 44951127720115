import React, { useState, useCallback, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { QueryKeys } from '../../constants/queryKeys.js';
import { useUser } from '../../UserContext.js';
import axiosInstance from '../../config/axios.js';
import Sidebar from '../Portal/Sidebar/Sidebar.js';
import Feed from '../Portal/Feed/Feed.js';
import SearchUsers from '../Portal/Social/SearchUsers.js';
import Following from '../Portal/Social/GetFollowing.js';
import Followers from '../Portal/Social/GetFollowers.js';
import ProfileModal from '../Portal/Sidebar/ProfileModal.js';
import LoadingPage from '../Spinner/LoadingPage.js';
import SEOHelmet from '../SEO/SEOHelmet.js';
import NavSteps from '../Navigation/NavSteps.js'; // Import NavSteps component
import './Home.css';

const Home = () => {
  const queryClient = useQueryClient();
  const { user, loading: userLoading } = useUser();
  const [showModal, setShowModal] = useState(false);
  const [profileUpdateTrigger, setProfileUpdateTrigger] = useState(0);

  const uid = queryClient.getQueryData(QueryKeys.auth.user)?.[0]?.uid;

  // Hooks må defineres før betingede retursetninger
  const handleModalClose = useCallback(() => setShowModal(false), []);

  const handleProfileUpdate = () => {
    setProfileUpdateTrigger((prev) => prev + 1);
  };

  const handleUpdateFollowing = useCallback(
    async (isFollowing, targetUserId) => {
      await Promise.all([
        queryClient.invalidateQueries(['following-list']),
        queryClient.invalidateQueries(`following-${uid}`),
        queryClient.invalidateQueries(`followers-${targetUserId}`),
        queryClient.invalidateQueries('users'),
      ]);
    },
    [queryClient, uid]
  );

  // Prefetch following and followers data
  useEffect(() => {
    if (user?.uid) {
      // Prefetch following and followers data after initial render
      queryClient.prefetchQuery(
        QueryKeys.following.list(user.uid),
        async () => {
          const response = await axiosInstance.get(
            `/api/getfollowing/${user.uid}`
          );
          return response.data;
        },
        { staleTime: 1000 * 60 * 20 } // 20 minutes
      );

      queryClient.prefetchQuery(
        QueryKeys.followers.list(user.uid),
        async () => {
          const response = await axiosInstance.get(
            `/api/getfollowers/${user.uid}`
          );
          return response.data;
        },
        { staleTime: 1000 * 60 * 20 } // 20 minutes
      );
    }
  }, [queryClient, user?.uid]);

  // Vis loading-siden bare hvis brukerdataene fortsatt laster
  if (userLoading) {
    return <LoadingPage />;
  }

  return (
    <div className="home-container">
      <SEOHelmet
        title="Min Portal - PlanCoo"
        description="Din personlige PlanCoo-portal for prosjektstyring og teamsamarbeid. Se dine oppdateringer, prosjekter og aktiviteter."
      />

      {/* NavSteps - No userData needed, it gets its own data */}
      <div className="nav-steps-wrapper" style={{ gridColumn: '1 / -1' }}>
        <NavSteps activeStep="home" />
      </div>

      <div className="sidebar">
        <Sidebar
          key={profileUpdateTrigger}
          onProfileUpdate={handleProfileUpdate}
        />
      </div>

      <div className="main-content">
        <Feed />
      </div>

      <div className="projects">
        <SearchUsers
          setSearchResults={() => {}}
          onUpdateFollowing={handleUpdateFollowing}
        />
        <Following
          uid={user?.uid || ''}
          onUpdateFollowing={handleUpdateFollowing}
        />
        <Followers
          uid={user?.uid || ''}
          onUpdateFollowing={handleUpdateFollowing}
        />
      </div>

      {showModal && <ProfileModal onClose={handleModalClose} />}
    </div>
  );
};

export default Home;
