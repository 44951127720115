import axios from 'axios';
import AuthService from '../services/authService.js';
import logger from '../utils/logger.js';
import { getAuth } from 'firebase/auth'; // Legg til denne importen

// Fiks baseURL - må være gyldig URL eller '/' for relative baner
const baseURL =
  process.env.NODE_ENV === 'production' ? '/' : 'http://localhost:7071';

const axiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Sikre at axiosInstance har CSRF-beskyttelse hvis applikasjonen bruker cookies
axiosInstance.defaults.xsrfCookieName = 'csrf_token';
axiosInstance.defaults.xsrfHeaderName = 'X-CSRF-Token';

// URL validation interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    // Hent fersk token før hver request
    const token = await AuthService.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // LEGG TIL: Hent og inkluder bruker-ID i headeren
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (currentUser) {
      config.headers['x-user-uid'] = currentUser.uid;
      // For debugging
      if (process.env.NODE_ENV !== 'production') {
        logger.log('Including user ID in request:', currentUser.uid);
      }
    }

    // Handle URL parameters if present in the URL
    if (config.url && config.params) {
      Object.entries(config.params).forEach(([key, value]) => {
        const placeholder = `{${key}}`;
        if (config.url.includes(placeholder)) {
          config.url = config.url.replace(
            placeholder,
            encodeURIComponent(value)
          );
          delete config.params[key]; // Remove used params
        }
      });
    }

    // Fjern logging i produksjon - dette eksponerer sensitive detaljer
    if (process.env.NODE_ENV !== 'production') {
      logger.log('Final URL:', config.url);
    }

    return config;
  },
  (error) => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

// Response validation og filtrering av sensitiv data
axiosInstance.interceptors.response.use(
  (response) => {
    // Sjekk om responsen er HTML i stedet for forventet JSON
    if (
      typeof response.data === 'string' &&
      response.data.includes('<!doctype html>')
    ) {
      throw new Error('Invalid response format');
    }

    // Hvis dette er et Firebase Auth-kall, filtrer sensitive data
    if (
      response.config.url?.includes('accounts:lookup') ||
      response.config.url?.includes('identitytoolkit')
    ) {
      // Aktiver filtrering av brukerdata
      if (response.data?.users?.length > 0) {
        response.data.users = response.data.users.map((user) => ({
          // Behold bare det absolutt nødvendige
          localId: user.localId,
          displayName: user.displayName,
          photoUrl: user.photoUrl,
          emailVerified: user.emailVerified,
        }));
      }
    }

    return response;
  },
  async (error) => {
    if (error.response?.status === 401) {
      await AuthService.refreshToken();
      return axiosInstance(error.config);
    }

    // Sikker feillogging
    if (error.response) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(
          'Response error:',
          error.response.status,
          error.response.data
        );
      } else {
        console.error('Response error:', error.response.status);
      }
    } else if (error.request) {
      console.error('No response received');
    } else {
      console.error('Axios configuration error:', error.message);
    }

    return Promise.reject(error);
  }
);

Object.freeze(axiosInstance);
export default axiosInstance;
