/**
 * Resizes and compresses an image following Azure best practices for storage optimization
 * @param {File|Blob} file - The image file to resize and compress
 * @param {Object} options - Resize options
 * @param {number} options.maxSizeKB - Maximum size in KB (default: 800)
 * @param {number} options.maxWidth - Maximum width (default: 800)
 * @param {number} options.maxHeight - Maximum height (default: 800)
 * @param {string} options.format - Output format (default: inferred from file, fallback to 'jpeg')
 * @param {number} options.quality - Compression quality 0-1 (default: 0.8)
 * @returns {Promise<Blob>} - Resized and compressed image blob
 */
export const resizeAndCompressImage = async (file, options = {}) => {
  const {
    maxSizeKB = 800,
    maxWidth = 800,
    maxHeight = 800,
    quality = 0.8,
  } = options;

  // Determine output format
  let format = options.format || null;
  if (!format) {
    // Infer format from file type or name
    if (file.type && file.type.includes('image/')) {
      format = file.type.split('/')[1];
    } else if (file.name) {
      const extension = file.name.split('.').pop().toLowerCase();
      if (['jpg', 'jpeg', 'png', 'webp', 'gif'].includes(extension)) {
        format = extension === 'jpg' ? 'jpeg' : extension;
      }
    }
    // Default to JPEG if format couldn't be determined
    if (!format || format === 'gif') format = 'jpeg';
  }

  return new Promise((resolve, reject) => {
    if (!(file instanceof Blob)) {
      reject(new Error('Parameter is not of type Blob or File.'));
      return;
    }

    // Skip processing for small images that are already under the size limit
    if (file.size / 1024 < maxSizeKB && file.size / 1024 < 200) {
      console.log('Image already small enough, skipping compression');
      resolve(file);
      return;
    }

    const img = new Image();
    const reader = new FileReader();

    reader.onload = () => {
      img.onload = () => {
        // Calculate new dimensions while maintaining aspect ratio
        let width = img.width;
        let height = img.height;
        let aspectRatio = width / height;

        if (width > maxWidth) {
          width = maxWidth;
          height = Math.round(width / aspectRatio);
        }

        if (height > maxHeight) {
          height = maxHeight;
          width = Math.round(height * aspectRatio);
        }

        // Create canvas and draw resized image
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = width;
        canvas.height = height;

        // Use crisp rendering for better quality
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';
        ctx.drawImage(img, 0, 0, width, height);

        // Try to compress with initial quality
        const compressWithQuality = (currentQuality) => {
          const mimeType = `image/${format}`;

          canvas.toBlob(
            (blob) => {
              if (!blob) {
                reject(new Error('Image compression failed'));
                return;
              }

              const fileSizeKB = blob.size / 1024;

              // If size is acceptable, resolve with blob
              if (fileSizeKB <= maxSizeKB) {
                // Create a new file object with proper name and type
                const resultFile = new File(
                  [blob],
                  `${file.name.split('.')[0]}.${format}`,
                  { type: mimeType }
                );
                resolve(resultFile);
              }
              // If quality is already low, but size is still too large, resolve anyway
              else if (currentQuality <= 0.5) {
                console.warn(
                  `Could not compress image to target size (${fileSizeKB.toFixed(1)}KB vs ${maxSizeKB}KB target)`
                );
                const resultFile = new File(
                  [blob],
                  `${file.name.split('.')[0]}.${format}`,
                  { type: mimeType }
                );
                resolve(resultFile);
              }
              // Try again with lower quality
              else {
                const newQuality = Math.max(0.5, currentQuality - 0.1);
                console.log(
                  `Reducing quality to ${newQuality} (current size: ${fileSizeKB.toFixed(1)}KB)`
                );
                compressWithQuality(newQuality);
              }
            },
            mimeType,
            currentQuality
          );
        };

        // Start compression
        compressWithQuality(quality);
      };

      img.onerror = () => reject(new Error('Failed to load image'));
      img.src = reader.result;
    };

    reader.onerror = () => reject(new Error('Failed to read file'));
    reader.readAsDataURL(file);
  });
};

export default resizeAndCompressImage;
