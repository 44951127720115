import { useState, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import axiosInstance from '../config/axios.js';
import { toast } from 'react-toastify';
import { QueryKeys } from '../constants/queryKeys.js';

const useFollowButton = (currentUserId) => {
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();

  const handleFollow = useCallback(
    async (targetUserId, isFollowing, onSuccess) => {
      if (!currentUserId || !targetUserId || isLoading) {
        toast.error('Unable to process follow action');
        return;
      }

      setIsLoading(true);

      // Cache previous state for rollback
      const previousCache = {
        following: queryClient.getQueryData(
          QueryKeys.following.list(currentUserId)
        ),
        followers: queryClient.getQueryData(
          QueryKeys.followers.list(targetUserId)
        ),
        users: queryClient.getQueryData(QueryKeys.users.all),
      };

      try {
        // Optimistic update
        const followingUpdate = (old) => {
          const oldData = old || [];
          if (isFollowing) {
            return [...oldData, { uid: targetUserId, isFollowing: true }];
          }
          return oldData.filter((f) => f.uid !== targetUserId);
        };

        queryClient.setQueryData(
          QueryKeys.following.list(currentUserId),
          followingUpdate
        );

        // Make API call
        const endpoint = isFollowing ? '/api/followuser' : '/api/unfollowuser';

        const response = await axiosInstance({
          method: isFollowing ? 'post' : 'delete',
          url: endpoint,
          data: { followerUid: currentUserId, followedUid: targetUserId },
        });

        if (response.status === 200) {
          // Invalidate and refetch relevant queries
          await Promise.all([
            queryClient.invalidateQueries(
              QueryKeys.following.list(currentUserId)
            ),
            queryClient.invalidateQueries(
              QueryKeys.followers.list(targetUserId)
            ),
            queryClient.invalidateQueries(QueryKeys.users.all),
          ]);

          onSuccess?.(isFollowing);
        }
      } catch (error) {
        // Rollback on error
        Object.entries(previousCache).forEach(([key, value]) => {
          queryClient.setQueryData(key, value);
        });

        toast.error(`Failed to ${isFollowing ? 'follow' : 'unfollow'} user`);
        console.error('Follow action error:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [currentUserId, queryClient, isLoading]
  );

  return { handleFollow, isLoading };
};

export default useFollowButton;
