import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUser } from '../../UserContext.js';
import axiosInstance from '../../config/axios.js';
import { QueryKeys } from '../../constants/queryKeys.js';
import './CompanyForm.css';
import CompanyLayout from './CompanyLayout.js';

const CreateCompanyForm = () => {
  const [companyName, setCompanyName] = useState('');
  const [BusinessArea, setBusinessArea] = useState('');
  const [isPublic, setIsPublic] = useState(true);
  const [error, setError] = useState('');
  const { user } = useUser();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // Business areas from CompanyProfileEdit
  const BUSINESS_AREAS = [
    'Bygg og anlegg',
    'Olje og gass',
    'Energi og fornybar',
    'Maritim og offshore',
    'Industri og produksjon',
    'Teknisk installasjon (el, VVS, automasjon)',
    'Prosjektering og rådgivning',
    'IT og systemintegrasjon',
    'Logistikk og leverandør',
    'Eiendom og eiendomsutvikling',
    'Miljø og bærekraft',
    'Offentlig sektor',
    'Privat bruk',
    'Annet',
  ];

  const createCompanyMutation = useMutation(
    async (companyData) => {
      // Use a hardcoded endpoint path instead of the environment variable to avoid space issues
      const apiEndpoint = '/api/CreateCompany';

      console.log('Sending request to:', apiEndpoint);

      const response = await axiosInstance.post(apiEndpoint, companyData, {
        headers: {
          'x-user-uid': user.uid,
        },
      });
      return response.data;
    },
    {
      onSuccess: async (data) => {
        // Update queries
        queryClient.invalidateQueries(QueryKeys.companies.all);

        // Invalider OG oppdater brukerdata for å sikre en oppdatert UI
        await queryClient.invalidateQueries(['userData', user?.uid]);
        await queryClient.refetchQueries(['userData', user?.uid], {
          active: true,
        });

        console.log('Create company response:', data);

        // Naviger til selskapsprofilen istedenfor hjem
        if (data && data.companyId) {
          toast.success('Selskap opprettet');
          navigate(`/companies/${data.companyId}`);
        } else {
          toast.success('Selskap opprettet');
          navigate('/home');
        }
      },
      onError: (error) => {
        console.error('Error creating company:', error);
        console.log(
          'Request data:',
          JSON.stringify({
            name: companyName.trim(),
            ownerUid: user.uid,
            BusinessArea: BusinessArea,
            IsPublic: isPublic,
          })
        );

        if (error.response) {
          console.log('Response status:', error.response.status);
          console.log('Response data:', error.response.data);
        }

        setError(
          error.response?.data?.message ||
            'Kunne ikke opprette selskap. Prøv igjen senere.'
        );
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    setError('');

    // Validering av obligatoriske felt
    if (!companyName.trim()) {
      setError('Selskapsnavn er påkrevd');
      return;
    }

    // Validering av bransje
    if (!BusinessArea) {
      setError('Bransje er påkrevd');
      return;
    }

    // Oppdater casen på feltene for å samsvare med backend-forventninger
    const companyData = {
      name: companyName.trim(),
      ownerUid: user.uid,
      BusinessArea: BusinessArea,
      IsPublic: isPublic,
    };

    console.log('Sending company data:', companyData);
    createCompanyMutation.mutate(companyData);
  };

  return (
    <CompanyLayout title="Opprett nytt selskap">
      <div className="create-company-container">
        {error && (
          <div className="alert alert-danger">
            <i className="fas fa-exclamation-circle"></i> {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="company-form">
          <div className="form-group">
            <label htmlFor="companyName">Selskapsnavn *</label>
            <input
              type="text"
              id="companyName"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              className={`form-control ${!companyName.trim() && error ? 'is-invalid' : ''}`}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="BusinessArea">Bransje *</label>
            <select
              id="BusinessArea"
              value={BusinessArea}
              onChange={(e) => setBusinessArea(e.target.value)}
              className={`form-control ${!BusinessArea && error ? 'is-invalid' : ''}`}
              required
            >
              <option value="">Velg bransje</option>
              {BUSINESS_AREAS.map((area) => (
                <option key={area} value={area}>
                  {area}
                </option>
              ))}
            </select>
            {!BusinessArea && error && (
              <div className="invalid-feedback">Bransje må velges</div>
            )}
          </div>

          <div className="form-group toggle-group">
            <label className="toggle-switch">
              <input
                type="checkbox"
                checked={isPublic}
                onChange={(e) => setIsPublic(e.target.checked)}
              />
              <span className="toggle-slider"></span>
            </label>
            <span>Gjør selskapet offentlig synlig</span>
          </div>

          <div className="form-actions">
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={() => navigate(-1)}
            >
              <i className="fas fa-times"></i> Avbryt
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={createCompanyMutation.isLoading}
            >
              {createCompanyMutation.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> Oppretter...
                </>
              ) : (
                <>
                  <i className="fas fa-plus-circle"></i> Opprett selskap
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </CompanyLayout>
  );
};

export default CreateCompanyForm;
