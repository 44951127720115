import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useCompany, useUpdateCompany } from '../../hooks/useCompanies.js';
import { useCompanyLogo } from '../../hooks/useCompanyLogo.js';
import CompanyLayout from './CompanyLayout.js';
import Spinner from '../Spinner/Spinner.js'; // Add missing import
import './CompanyEdit.css'; // Add this import line

const BUSINESS_AREAS = [
  'Bygg og anlegg',
  'Olje og gass',
  'Energi og fornybar',
  'Maritim og offshore',
  'Industri og produksjon',
  'Teknisk installasjon (el, VVS, automasjon)',
  'Prosjektering og rådgivning',
  'IT og systemintegrasjon',
  'Logistikk og leverandør',
  'Eiendom og eiendomsutvikling',
  'Miljø og bærekraft',
  'Offentlig sektor',
  'Privat bruk',
  'Annet',
];

/**
 * Komponent for redigering av selskapsprofil
 * @param {Object} props - Komponentens props
 * @param {Object} props.company - Selskapsobjektet som skal redigeres
 * @param {Function} [props.onCancel] - Funksjon som kalles ved avbryt
 * @param {Function} [props.onSuccess] - Funksjon som kalles etter vellykket lagring
 */
const CompanyProfileEdit = ({
  company: initialCompany,
  onCancel = null,
  onSuccess = null,
}) => {
  const navigate = useNavigate();
  const { id } = useParams(); // Add this line to get ID from URL

  // IMPORTANT: Move all hooks to the top level - no conditionals before hooks
  const [activeTab, setActiveTab] = useState('basic');
  const [loading, setLoading] = useState(!initialCompany && !!id);
  const [formData, setFormData] = useState({
    name: '',
    BusinessArea: '',
    About: '',
    Website: '',
    IsPublic: true,
    Logo: '',
    SocialMediaLinks: [],
  });
  const [socialMediaInput, setSocialMediaInput] = useState({
    name: '',
    url: '',
  });
  const [logoFile, setLogoFile] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);

  // Initialize all hooks first, regardless of conditions
  const updateCompanyMutation = useUpdateCompany();
  const logoMutation = useCompanyLogo();

  // Fetch company data if not provided and id is available from route
  const { data: fetchedCompany, isLoading: isFetchingCompany } = useCompany(
    id ? parseInt(id, 10) : null,
    {
      enabled: !initialCompany && !!id,
      onSuccess: () => setLoading(false),
    }
  );

  // Use initialCompany if provided, otherwise use fetchedCompany
  const company = initialCompany || fetchedCompany;

  // Initialize form data after company data is available
  useEffect(() => {
    if (company) {
      setFormData({
        name: company.name || '',
        BusinessArea: company.BusinessArea || '',
        About: company.About || '',
        Website: company.Website || '',
        IsPublic: company.IsPublic !== undefined ? company.IsPublic : true,
        Logo: company.Logo || '',
        SocialMediaLinks: [],
      });
    }
  }, [company]);

  // Parse social media links on component mount
  useEffect(() => {
    if (company?.SocialMediaLinks) {
      try {
        const links =
          typeof company.SocialMediaLinks === 'string'
            ? JSON.parse(company.SocialMediaLinks)
            : company.SocialMediaLinks;

        if (Array.isArray(links)) {
          setFormData((prev) => ({
            ...prev,
            SocialMediaLinks: links,
          }));
        }
      } catch (error) {
        console.error('Failed to parse social media links:', error);
        // Set empty array as fallback
        setFormData((prev) => ({
          ...prev,
          SocialMediaLinks: [],
        }));
      }
    }
  }, [company]);

  // Local handler for cancel that respects the prop or uses default behavior
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    } else {
      navigate(`/companies/${id}`);
    }
  };

  // Local handler for success that respects the prop or uses default behavior
  const handleSuccess = (updatedData) => {
    if (onSuccess) {
      onSuccess(updatedData);
    } else {
      toast.success('Selskapsprofil oppdatert');
      navigate(`/companies/${id}`);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSocialMediaInputChange = (e) => {
    const { name, value } = e.target;
    setSocialMediaInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addSocialMediaLink = () => {
    if (!socialMediaInput.name || !socialMediaInput.url) {
      toast.warn('Både navn og URL må fylles ut for sosiale medier');
      return;
    }

    // Validate URL format
    try {
      new URL(socialMediaInput.url);
    } catch (error) {
      toast.warn('Vennligst angi en gyldig URL (inkluder https://)');
      return;
    }

    setFormData((prev) => ({
      ...prev,
      SocialMediaLinks: [...prev.SocialMediaLinks, { ...socialMediaInput }],
    }));

    // Clear inputs
    setSocialMediaInput({ name: '', url: '' });
  };

  const removeSocialMediaLink = (index) => {
    setFormData((prev) => ({
      ...prev,
      SocialMediaLinks: prev.SocialMediaLinks.filter((_, i) => i !== index),
    }));
  };

  const handleLogoFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Validate file type
    const validTypes = ['image/jpeg', 'image/png', 'image/webp', 'image/gif'];
    if (!validTypes.includes(file.type)) {
      toast.error('Vennligst velg et gyldig bilde (JPG, PNG, WebP eller GIF)');
      return;
    }

    // Update UI immediately with preview
    setLogoFile(file);

    // Create a preview immediately for better UX
    const reader = new FileReader();
    reader.onload = (e) => setLogoPreview(e.target.result);
    reader.readAsDataURL(file);

    // Show file size info
    const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
    if (fileSizeMB > 2) {
      toast.info(
        `Bildet vil bli komprimert før opplasting (nåværende størrelse: ${fileSizeMB}MB)`
      );
    }
  };

  // Funksjon for å sjekke om det finnes endringer som skal lagres
  const hasChanges = () => {
    if (!company) return false;

    // Sjekk om logo-filen er endret
    if (logoFile) return true;

    // Sjekk om andre feltene er endret
    if (formData.name !== company.name) return true;
    if (formData.BusinessArea !== company.BusinessArea) return true;
    if (formData.About !== company.About) return true;
    if (formData.Website !== company.Website) return true;
    if (formData.IsPublic !== company.IsPublic) return true;
    if (formData.Logo !== company.Logo) return true;

    // Sjekk om sosiale medier er endret
    const currentSocialLinks = JSON.stringify(formData.SocialMediaLinks);
    const originalSocialLinks =
      typeof company.SocialMediaLinks === 'string'
        ? company.SocialMediaLinks
        : JSON.stringify(company.SocialMediaLinks || []);

    if (currentSocialLinks !== originalSocialLinks) return true;

    return false;
  };

  const validateWebsiteUrl = (url) => {
    if (!url) return true; // Empty URL is valid

    // Remove protocol for validation if present
    const domainPart = url.replace(/^https?:\/\//, '');

    // Don't validate empty domains
    if (!domainPart.trim()) return true;

    // Validate with https:// prefix
    try {
      new URL(`https://${domainPart}`);
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate website URL if present (simplified validation)
    if (formData.Website && !validateWebsiteUrl(formData.Website)) {
      toast.error('Ugyldig nettadresse. Vennligst oppgi et gyldig domene.');
      return;
    }

    // Always ensure proper https:// prefix (even if user managed to include http://)
    let websiteUrl = formData.Website ? formData.Website.trim() : '';
    if (websiteUrl && !websiteUrl.match(/^https?:\/\//)) {
      websiteUrl = `https://${websiteUrl}`;
    } else if (websiteUrl && websiteUrl.startsWith('http://')) {
      // Upgrade http to https for security
      websiteUrl = websiteUrl.replace(/^http:\/\//, 'https://');
    }

    try {
      // Show a loading toast to indicate process has started
      const toastId = toast.info('Oppdaterer selskapsprofil...', {
        autoClose: false,
        closeButton: false,
      });

      let finalLogoUrl = formData.Logo;

      // If there's a new logo file, upload it first
      if (logoFile) {
        try {
          // Use logoMutation to upload the file
          finalLogoUrl = await logoMutation.mutateAsync({
            file: logoFile,
            companyId: company.id,
          });
        } catch (uploadError) {
          toast.dismiss(toastId);
          toast.error('Kunne ikke laste opp logo. Prøv igjen senere.');
          console.error('Logo upload error:', uploadError);
          return;
        }
      }

      // Prepare data for API with updated logo URL and properly formatted website URL
      const updateData = {
        ...formData,
        Website: websiteUrl, // Use the properly formatted URL
        Logo: finalLogoUrl,
        id: company.id,
        SocialMediaLinks: JSON.stringify(formData.SocialMediaLinks),
      };

      // Execute the update mutation
      updateCompanyMutation.mutate(updateData, {
        onSuccess: (updatedData) => {
          toast.dismiss(toastId);
          toast.success('Selskapsprofil oppdatert');
          // Use the handleSuccess function to either call the prop or navigate
          handleSuccess(updatedData);
        },
        onError: (error) => {
          toast.dismiss(toastId);
          console.error('Company update error:', error);
          toast.error(
            error.response?.data?.message ||
              'Kunne ikke oppdatere selskapsprofil. Prøv igjen senere.'
          );
        },
      });
    } catch (error) {
      toast.error('En feil oppstod. Vennligst prøv igjen.');
      console.error('Submission error:', error);
    }
  };

  // Handle loading state - moved after all hooks
  if ((loading || isFetchingCompany) && !company) {
    return <Spinner text="Laster selskapsdata..." />;
  }

  // Handle error state when company is not found
  if (!company && id) {
    return (
      <div className="alert alert-danger m-4">
        <h3>Feil ved lasting av selskap</h3>
        <p>Fant ikke selskapet med ID {id}.</p>
        <button className="btn btn-primary" onClick={() => navigate(-1)}>
          Tilbake
        </button>
      </div>
    );
  }

  // Update the return section to use tabs
  return (
    <CompanyLayout title={`Rediger ${company?.name || 'selskap'}`}>
      <div className="company-edit-container">
        <div className="company-edit-header">
          <h2>Rediger selskapsprofil</h2>
        </div>

        <div className="company-edit-tabs">
          <div
            className={`edit-tab ${activeTab === 'basic' ? 'active' : ''}`}
            onClick={() => setActiveTab('basic')}
          >
            <span className="edit-tab-indicator">1</span>
            <span className="edit-tab-label">Grunnleggende</span>
          </div>
          <div
            className={`edit-tab ${activeTab === 'details' ? 'active' : ''}`}
            onClick={() => setActiveTab('details')}
          >
            <span className="edit-tab-indicator">2</span>
            <span className="edit-tab-label">Detaljer</span>
          </div>
          <div
            className={`edit-tab ${activeTab === 'social' ? 'active' : ''}`}
            onClick={() => setActiveTab('social')}
          >
            <span className="edit-tab-indicator">3</span>
            <span className="edit-tab-label">Sosiale medier</span>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="company-edit-content">
          {/* Basic Information Tab */}
          {activeTab === 'basic' && (
            <>
              <div className="form-row">
                <div className="form-group">
                  <label className="form-label" htmlFor="name">
                    Selskapsnavn *
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="form-control"
                    required
                  />
                </div>

                <div className="form-group">
                  <label className="form-label" htmlFor="BusinessArea">
                    Bransje *
                  </label>
                  <select
                    id="BusinessArea"
                    name="BusinessArea"
                    value={formData.BusinessArea}
                    onChange={handleInputChange}
                    className="form-control"
                    required
                  >
                    <option value="">Velg bransje</option>
                    {BUSINESS_AREAS.map((area) => (
                      <option key={area} value={area}>
                        {area}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="About">
                  Om selskapet
                </label>
                <textarea
                  id="About"
                  name="About"
                  value={formData.About || ''}
                  onChange={handleInputChange}
                  className="form-control"
                  rows="4"
                  placeholder="Beskriv selskapet, tjenester, verdier, etc."
                />
              </div>

              <div className="form-group toggle-group">
                <label className="toggle-switch">
                  <input
                    type="checkbox"
                    name="IsPublic"
                    checked={formData.IsPublic}
                    onChange={handleInputChange}
                  />
                  <span className="toggle-slider"></span>
                </label>
                <span>Gjør selskapet offentlig tilgjengelig</span>
              </div>
            </>
          )}

          {/* Details Tab */}
          {activeTab === 'details' && (
            <>
              <div className="form-group">
                <label className="form-label" htmlFor="Website">
                  Nettside
                </label>
                <div className="website-input-wrapper">
                  <div className="protocol-prefix">https://</div>
                  <input
                    type="text"
                    id="Website"
                    name="Website"
                    value={
                      formData.Website
                        ? formData.Website.replace(/^https?:\/\//, '')
                        : ''
                    }
                    onChange={(e) => {
                      // Remove any protocol if user accidentally types it
                      const value = e.target.value.replace(/^https?:\/\//, '');
                      setFormData((prev) => ({ ...prev, Website: value }));
                    }}
                    className="form-control with-prefix"
                    placeholder="www.example.com"
                  />
                </div>
                <span className="form-hint">
                  Du trenger ikke å skrive &quot;https://&quot; - det legges til
                  automatisk
                </span>
              </div>

              <div className="form-group">
                <label className="form-label">Logo</label>

                {/* Logo preview */}
                <div className="logo-preview-container">
                  {logoPreview ? (
                    <img
                      src={logoPreview}
                      alt="Logo forhåndsvisning"
                      className="logo-preview-image"
                    />
                  ) : formData.Logo ? (
                    <img
                      src={formData.Logo}
                      alt="Nåværende logo"
                      className="logo-preview-image"
                      onError={(e) => {
                        e.target.src = 'https://placehold.co/150?text=Logo';
                      }}
                    />
                  ) : (
                    <div className="logo-placeholder">
                      <i className="fas fa-building"></i>
                      <span>Ingen logo</span>
                    </div>
                  )}
                </div>

                {/* File uploader - Simplified version without separate upload button */}
                <div className="logo-upload-controls">
                  <input
                    type="file"
                    id="logo-file"
                    accept="image/jpeg,image/png,image/gif,image/webp"
                    onChange={handleLogoFileChange}
                    className="file-input"
                  />
                  <label
                    htmlFor="logo-file"
                    className="btn btn-outline-primary"
                  >
                    <i className="fas fa-image"></i> Velg nytt bilde
                  </label>

                  {logoFile && (
                    <span className="selected-file-name">
                      Valgt fil: {logoFile.name}
                    </span>
                  )}
                </div>

                <span className="form-hint">
                  Last opp en logo for selskapet (JPG, PNG, WebP eller GIF).
                  Bildet vil bli komprimert automatisk.
                  <br />
                  <strong>
                    Klikk &quot;Lagre endringer&quot; når du er ferdig.
                  </strong>
                </span>
              </div>

              {/* Behold det eksisterende logo URL-feltet som backup */}
              <div className="form-group">
                <label className="form-label" htmlFor="Logo">
                  Logo URL (valgfritt)
                </label>
                <input
                  type="url"
                  id="Logo"
                  name="Logo"
                  value={formData.Logo || ''}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="https://example.com/logo.png"
                />
                <span className="form-hint">
                  Du kan også angi en direkte lenke til en logo
                </span>
              </div>
            </>
          )}

          {/* Social Media Tab */}
          {activeTab === 'social' && (
            <>
              <h4>Sosiale medier</h4>

              {formData.SocialMediaLinks.length > 0 ? (
                <div className="social-media-list">
                  {formData.SocialMediaLinks.map((link, index) => (
                    <div key={index} className="social-media-item">
                      <div className="social-media-fields">
                        <div
                          className="form-group"
                          style={{ minWidth: '120px' }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            value={link.name}
                            disabled
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            value={link.url}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="social-media-controls">
                        <button
                          type="button"
                          className="btn btn-outline-danger btn-sm"
                          onClick={() => removeSocialMediaLink(index)}
                        >
                          <i className="fas fa-trash-alt"></i>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-muted">
                  Ingen sosiale medier er lagt til ennå.
                </p>
              )}

              <div className="social-media-item">
                <div className="social-media-fields">
                  <div className="form-group" style={{ minWidth: '120px' }}>
                    <input
                      type="text"
                      name="name"
                      value={socialMediaInput.name}
                      onChange={handleSocialMediaInputChange}
                      className="form-control"
                      placeholder="Navn (f.eks. LinkedIn)"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="url"
                      value={socialMediaInput.url}
                      onChange={handleSocialMediaInputChange}
                      className="form-control"
                      placeholder="https://..."
                    />
                  </div>
                </div>
                <div className="social-media-controls">
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-sm"
                    onClick={addSocialMediaLink}
                  >
                    <i className="fas fa-plus"></i>
                  </button>
                </div>
              </div>
            </>
          )}

          <div className="form-actions">
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={handleCancel}
            >
              Avbryt
            </button>
            <button
              type="submit"
              className={`btn btn-primary ${hasChanges() ? 'has-changes' : ''}`}
              disabled={updateCompanyMutation.isLoading || !hasChanges()}
            >
              {updateCompanyMutation.isLoading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> Lagrer...
                </>
              ) : (
                <>
                  <i className="fas fa-save"></i> Lagre endringer
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </CompanyLayout>
  );
};

// Add prop types
CompanyProfileEdit.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    Logo: PropTypes.string,
    About: PropTypes.string,
    Website: PropTypes.string,
    BusinessArea: PropTypes.string,
    IsPublic: PropTypes.bool,
    SocialMediaLinks: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          url: PropTypes.string,
        })
      ),
    ]),
  }),
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default CompanyProfileEdit;
