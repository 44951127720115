import { useMutation } from 'react-query';
import axiosInstance from '../config/axios.js';
import logger from '../utils/logger.js';
import { resizeAndCompressImage } from '../utils/resizeimg.js';
import { useUser } from '../UserContext.js';

/**
 * Hook for uploading company logos to Azure Blob Storage
 * Following Azure best practices for image handling and storage optimization
 * @returns {Object} Mutation object for company logo uploads
 */
export const useCompanyLogo = () => {
  const { user } = useUser();

  return useMutation(async ({ file, companyId }) => {
    try {
      if (!companyId) {
        throw new Error('Company ID is required for logo upload');
      }

      if (!user?.uid) {
        throw new Error('User must be authenticated to upload company logo');
      }

      // Validate file type before processing
      if (!file.type.startsWith('image/')) {
        throw new Error('Selected file is not an image');
      }

      // Step 1: Resize and compress the image following Azure best practices
      logger.log('Resizing and compressing company logo...');
      const compressedFile = await resizeAndCompressImage(file, {
        maxSizeKB: 500, // Azure CDN optimized size threshold
        maxWidth: 600, // Recommended size for company logos
        maxHeight: 600, // Maintains aspect ratio
        quality: 0.85, // Start with higher quality
      });

      // Sanitize filename - use companyId in the name for better identification
      const fileExtension = compressedFile.type.split('/')[1] || 'jpg';
      const sanitizedFileName =
        `company-logo-${companyId}-${Date.now()}.${fileExtension}`
          .replace(/\s+/g, '-')
          .replace(/[^\w\-.]/g, '');

      logger.log(
        `Original size: ${(file.size / 1024).toFixed(1)}KB, Compressed size: ${(compressedFile.size / 1024).toFixed(1)}KB`
      );

      // Step 2: Get SAS URL for secure upload - NOW USING THE NEW ENDPOINT
      const sasResponse = await axiosInstance.get('/api/companylogos', {
        params: {
          fileName: sanitizedFileName,
          companyId: companyId,
          // No need to pass userId as axiosInstance already includes it in headers
        },
      });

      logger.log('Company logo SAS response:', sasResponse.data);

      if (!sasResponse.data?.sasUrl) {
        throw new Error('Invalid SAS URL received for company logo');
      }

      // Step 3: Upload to Azure Blob Storage with proper content type
      const uploadResponse = await fetch(sasResponse.data.sasUrl, {
        method: 'PUT',
        headers: {
          'x-ms-blob-type': 'BlockBlob',
          'Content-Type': compressedFile.type || 'image/jpeg',
          'Cache-Control': 'max-age=31536000', // 1 year caching for better CDN performance
        },
        body: compressedFile,
      });

      if (!uploadResponse.ok) {
        throw new Error(
          `Company logo upload failed: ${uploadResponse.statusText}`
        );
      }

      // Step 4: Use CDN URL from response for optimized delivery
      const cdnUrl =
        sasResponse.data.cdnUrl ||
        `https://plancooblobcdn.azureedge.net/profile-pictures/${sasResponse.data.blobName}`;

      logger.log('Company logo uploaded, CDN URL:', cdnUrl);

      return cdnUrl;
    } catch (error) {
      logger.error('Company logo upload error:', error);
      throw error;
    }
  });
};
