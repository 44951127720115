import { useQuery } from 'react-query';
import axiosInstance from '../config/axios.js';
import { QueryKeys } from '../constants/queryKeys.js';

export const useProjectTasks = (projectId) => {
  return useQuery(
    QueryKeys.tasks.byProject(projectId),
    async () => {
      if (!projectId) return [];

      try {
        const response = await axiosInstance.get(
          `/api/gettasks?projectId=${projectId}`
        );
        return response.data || [];
      } catch (error) {
        console.error('Error fetching project tasks:', error);
        throw error;
      }
    },
    {
      enabled: !!projectId,
      staleTime: 5 * 60 * 1000, // 5 minutter
      cacheTime: 30 * 60 * 1000,
      retry: 1,
    }
  );
};
