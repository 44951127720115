import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext.js';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  auth,
} from '../firebaseConfig.js';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import AuthService from '../services/authService.js';
import axiosInstance from '../config/axios.js';
import logger from '../utils/logger.js';

export const useFirebaseAuth = () => {
  const navigate = useNavigate();
  const { setUser } = useUser();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  // Rate limiting implementation
  const loginAttemptsRef = useRef(0);
  const lastLoginAttemptRef = useRef(0);

  // Password validation
  const validatePassword = (password) => {
    const minLength = 8;
    const hasLetter = /[a-zA-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return password.length >= minLength && hasLetter && hasNumber && hasSpecial;
  };

  // Rate limiting check
  const checkRateLimit = () => {
    const now = Date.now();
    const ATTEMPT_LIMIT = 5;
    const COOLDOWN_PERIOD = 5 * 60 * 1000; // 5 minutes

    if (now - lastLoginAttemptRef.current > COOLDOWN_PERIOD) {
      // Reset if cooldown period has passed
      loginAttemptsRef.current = 0;
    }

    if (loginAttemptsRef.current >= ATTEMPT_LIMIT) {
      return false; // Rate limited
    }

    loginAttemptsRef.current++;
    lastLoginAttemptRef.current = now;
    return true; // Not rate limited
  };

  const handleGoogleSignIn = async (setMessage) => {
    if (!checkRateLimit()) {
      setMessage(t('rate_limit_exceeded'));
      return;
    }

    setLoading(true);
    try {
      const tempProvider = new GoogleAuthProvider();

      // Mer robust provider-oppsett
      const lastEmail = localStorage.getItem('lastLoginEmail');
      const customParams = {
        prompt: 'select_account',
      };

      if (lastEmail) {
        customParams.login_hint = lastEmail;
      }

      tempProvider.setCustomParameters(customParams);
      tempProvider.addScope('email');
      tempProvider.addScope('profile');

      // Forbedret feilhåndtering og logging
      logger.log('Starting Google Sign-In with popup...');

      const result = await signInWithPopup(auth, tempProvider).catch(
        (error) => {
          // Detaljert feillogging
          logger.error('Popup error details:', {
            code: error.code,
            message: error.message,
            email: error.customData?.email,
          });

          // Håndter "popup blocked" feil spesifikt
          if (error.code === 'auth/popup-blocked') {
            setMessage(t('popup_blocked'));
          } else {
            throw error; // La andre feil propagere
          }
        }
      );

      // Resten av koden forblir stort sett uendret
      if (result && result.user) {
        const user = result.user;

        // Lagre e-posten for fremtidige innlogginger
        if (user.email) {
          localStorage.setItem('lastLoginEmail', user.email);
        }

        // Sjekk om det er en ny bruker
        const isNewUser = result._tokenResponse?.isNewUser ?? false;

        if (isNewUser) {
          const userData = {
            firebaseUserId: user.uid,
            email: user.email,
            name: user.displayName || user.email.split('@')[0],
            subscription_name: 'Gratis',
            subscription_status: 'inaktiv',
            subscription_end: new Date().toISOString(),
            company: '',
            profilepicurl: user.photoURL || '',
          };

          await axiosInstance.post('/api/AddFirebaseUsers', userData);
          setMessage(t('account_created'));
        } else {
          setMessage(t('welcome_back'));

          try {
            const userDetails = await AuthService.fetchUserDetails(user.uid);
            if (userDetails) {
              queryClient.setQueryData('customerId', userDetails.customerId);
              queryClient.setQueryData('currentUserName', userDetails.name);
              queryClient.setQueryData(
                'currentUserCompany',
                userDetails.Company
              );
            }
          } catch (error) {
            logger.error('Failed to fetch user details:', error);
            // Don't expose this error to user interface
          }
        }

        setUser(user);

        // Safer navigation implementation
        navigate('/Home');
      }
    } catch (error) {
      logger.error('Failed to sign in with Google:', error);
      setMessage(t('auth_generic_error'));
    } finally {
      setLoading(false);
    }
  };

  const handleEmailSignIn = async (email, password, setMessage) => {
    // Input validation
    if (!email || !password) {
      setMessage(t('input_required'));
      return;
    }

    // Check rate limiting
    if (!checkRateLimit()) {
      setMessage(t('rate_limit_exceeded'));
      return;
    }

    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      setUser(user);
      setMessage(t('login_success'));

      try {
        const userDetails = await AuthService.fetchUserDetails(user.uid);
        if (userDetails) {
          queryClient.setQueryData('customerId', userDetails.customerId);
          queryClient.setQueryData('currentUserName', userDetails.name);
          queryClient.setQueryData('currentUserCompany', userDetails.Company);
        }
      } catch (error) {
        logger.error('Failed to fetch user details:', error);
        // Don't expose this error to user interface
      }

      navigate('/Home');
    } catch (error) {
      logger.error('Email sign-in error:', error);

      // Use a generic error message regardless of specific error
      // to prevent information disclosure about existing accounts
      setMessage(t('auth_generic_error'));
    } finally {
      setLoading(false);
    }
  };

  const handleEmailRegistration = async (email, password, setMessage) => {
    // Input validation
    if (!email || !password) {
      setMessage(t('input_required'));
      return;
    }

    // Password strength validation
    if (!validatePassword(password)) {
      setMessage(t('password_too_weak'));
      return;
    }

    setLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      const userData = {
        firebaseUserId: user.uid,
        email: user.email,
        name: user.email.split('@')[0],
        subscription_name: 'Gratis',
        subscription_status: 'inaktiv',
        subscription_end: new Date().toISOString(),
        company: '',
        profilepicurl: '',
      };

      await axiosInstance.post('/api/AddFirebaseUsers', userData);

      setUser(user);
      setMessage(t('account_created'));
      navigate('/Home');
    } catch (error) {
      logger.error('Registration error:', error);

      // Generic error message to prevent account enumeration
      setMessage(t('auth_generic_error'));
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordReset = async (email, setMessage) => {
    if (!email) {
      setMessage(t('password_reset_required_email'));
      return;
    }

    setLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      // Use generic message that doesn't confirm account existence
      setMessage(t('password_reset_sent'));
    } catch (error) {
      logger.error('Password reset error:', error);
      // Use same generic message even on error to prevent account enumeration
      setMessage(t('password_reset_sent'));
    } finally {
      setLoading(false);
    }
  };

  return {
    handleGoogleSignIn,
    handleEmailSignIn,
    handleEmailRegistration,
    handlePasswordReset,
    loading,
  };
};
