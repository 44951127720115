import { initializeApp } from 'firebase/app';
import {
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signOut,
  onAuthStateChanged,
  signInWithRedirect,
  getRedirectResult,
} from 'firebase/auth';
import logger from './utils/logger.js';

// Firebase-oppsett
let firebaseApp = null;
let firebaseAuth = null;

/**
 * Henter Firebase-konfigurasjon fra miljøvariabler
 * @returns {Object} Firebase konfigurasjonsobjekt
 */
const createFirebaseConfig = () => {
  // For verifiserte OAuth-apper, bruk alltid Firebase-domenet for auth
  const authDomain = 'planningtool-dad.firebaseapp.com';

  const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: authDomain, // Fast Firebase-domene
    projectId: process.env.REACT_APP_PROJECT_ID,
    // andre konfigurasjonsverdier...
  };

  return config;
};

/**
 * Initialiserer Firebase Client SDK (kun én gang)
 * @returns {Object} Firebase auth og app instances
 */
const initializeFirebase = () => {
  if (firebaseApp && firebaseAuth) {
    return { app: firebaseApp, auth: firebaseAuth };
  }

  const firebaseConfig = createFirebaseConfig();
  firebaseApp = initializeApp(firebaseConfig);
  firebaseAuth = getAuth(firebaseApp);

  logger.log('Firebase Client SDK initialized successfully');
  return { app: firebaseApp, auth: firebaseAuth };
};

// Initialiser Firebase
const { app, auth } = initializeFirebase();

// Providers
const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({
  prompt: 'select_account',
  login_hint: '', // Tom streng tvinger Google til å vise alle kontoer
  hd: '*', // Tillater alle domener (inkludert gmail.com)
});
googleProvider.addScope('email');
googleProvider.addScope('profile');

const microsoftProvider = new OAuthProvider('microsoft.com');
microsoftProvider.setCustomParameters({
  prompt: 'select_account',
  tenant: process.env.REACT_APP_MICROSOFT_TENANT_ID,
  domain_hint: 'organizations',
});
microsoftProvider.addScope('openid');
microsoftProvider.addScope('profile');
microsoftProvider.addScope('email');

// Improved token refresh management with proactive refresh
const setupAuthListeners = () => {
  let tokenRefreshTimeout;

  // Function to check token expiration
  const checkAndScheduleRefresh = async (user) => {
    if (!user) return;

    try {
      // Get the token without forcing refresh
      const token = await user.getIdToken(false);

      // Decode token to check expiration (JWT tokens have 3 parts separated by dots)
      const tokenParts = token.split('.');
      if (tokenParts.length !== 3) return;

      // Decode the payload (second part)
      const payload = JSON.parse(atob(tokenParts[1]));
      const expirationTime = payload.exp * 1000; // Convert to milliseconds
      const currentTime = Date.now();

      // Calculate time until expiration
      const timeUntilExpiration = expirationTime - currentTime;

      // If token expires in less than 10 minutes, refresh it
      // If it's valid for longer, schedule refresh for 5 minutes before expiration
      const REFRESH_THRESHOLD = 10 * 60 * 1000; // 10 minutes
      const REFRESH_MARGIN = 5 * 60 * 1000; // 5 minutes before expiry

      if (timeUntilExpiration < REFRESH_THRESHOLD) {
        // Immediate refresh needed
        await user.getIdToken(true);
        logger.log('Token refreshed immediately due to imminent expiration');
      } else {
        // Schedule refresh for 5 minutes before expiration
        const refreshIn = timeUntilExpiration - REFRESH_MARGIN;

        // Clear any existing timeout
        if (tokenRefreshTimeout) clearTimeout(tokenRefreshTimeout);

        // Schedule the next refresh
        tokenRefreshTimeout = setTimeout(async () => {
          await user.getIdToken(true);
          logger.log('Token proactively refreshed before expiration');
          // Schedule the next refresh
          checkAndScheduleRefresh(user);
        }, refreshIn);

        logger.log(
          `Token valid, next refresh scheduled in ${Math.round(refreshIn / 60000)} minutes`
        );
      }
    } catch (error) {
      logger.error('Error checking token expiration:', error);
    }
  };

  // Listen for token changes
  auth.onIdTokenChanged(async (user) => {
    // Clear existing timeout if any
    if (tokenRefreshTimeout) {
      clearTimeout(tokenRefreshTimeout);
    }

    if (user) {
      // Check and schedule the next refresh
      await checkAndScheduleRefresh(user);
    }
  });
};

// Start token refresh listeners
setupAuthListeners();

// Eksporter alt som er nødvendig
export {
  app,
  auth,
  googleProvider,
  microsoftProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signOut,
  onAuthStateChanged,
  signInWithRedirect,
  getRedirectResult,
};
