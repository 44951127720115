import { useMutation, useQueryClient } from 'react-query';
import axiosInstance from '../config/axios.js';
import { QueryKeys } from '../constants/queryKeys.js';

export const useDeleteComment = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ comment_id, userUid }) => {
      const url = `/api/deletecomment/${comment_id}?UserUid=${userUid}`;
      const response = await axiosInstance.delete(url);
      return response.data;
    },
    {
      onMutate: async ({ comment_id, feed_id }) => {
        // Cancel any outgoing refetches
        await queryClient.cancelQueries(QueryKeys.feed.list(1));
        await queryClient.cancelQueries(QueryKeys.feed.comments(feed_id));

        // Snapshot the previous values
        const previousPosts = queryClient.getQueryData(QueryKeys.feed.list(1));
        const previousComments = queryClient.getQueryData(
          QueryKeys.feed.comments(feed_id)
        );

        // Update comments directly in the query cache
        queryClient.setQueryData(QueryKeys.feed.comments(feed_id), (old) => {
          if (!old) return old;
          return {
            ...old,
            comments: old.comments.filter(
              (comment) => comment.comment_id !== comment_id
            ),
          };
        });

        // Also update the comments in the posts list
        queryClient.setQueryData(QueryKeys.feed.list(1), (old) => {
          if (!old) return old;
          return old.map((post) => {
            if (post.id === feed_id) {
              return {
                ...post,
                comments: post.comments.filter(
                  (comment) => comment.comment_id !== comment_id
                ),
              };
            }
            return post;
          });
        });

        return { previousPosts, previousComments };
      },
      onSuccess: () => {
        queryClient.invalidateQueries(
          ['posts'],
          { staleTime: 1000 * 60 * 5 } // 5 minutes stale time
        );
        queryClient.invalidateQueries(
          ['infinite-posts'],
          { staleTime: 1000 * 60 * 5 } // 5 minutes stale time
        );
      },
      onError: (err, { feed_id }, context) => {
        console.error('Error deleting comment:', err);

        // Restore previous data on error
        queryClient.setQueryData(
          QueryKeys.feed.comments(feed_id),
          context.previousComments
        );

        queryClient.setQueryData(QueryKeys.feed.list(1), context.previousPosts);
      },
      onSettled: ({ feed_id }) => {
        queryClient.invalidateQueries(
          QueryKeys.feed.comments(feed_id),
          { staleTime: 1000 * 60 * 5 } // 5 minutes stale time
        );
        queryClient.invalidateQueries(
          QueryKeys.feed.list(),
          { staleTime: 1000 * 60 * 5 } // 5 minutes stale time
        );
      },
      retry: (failureCount, error) => {
        if (error.response?.status === 401) {
          return failureCount < 2; // Retry once for auth errors
        }
        return failureCount < 1; // Don't retry for other errors
      },
      // Add consistent configuration options
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 30, // 30 minutes
    }
  );
};
