import { useQuery, useMutation, useQueryClient } from 'react-query';
import axiosInstance from '../config/axios.js';
import { QueryKeys } from '../constants/queryKeys.js';
import logger from '../utils/logger.js';

// API-endepunkter
const API_GET_COMPANIES =
  process.env.REACT_APP_GET_COMPANIES || '/api/GetCompany';
const API_GET_COMPANY = process.env.REACT_APP_GET_COMPANY || '/api/GetCompany';
const API_UPDATE_COMPANY =
  process.env.REACT_APP_UPDATE_COMPANY || '/api/UpdateCompany';
const API_GET_COMPANY_PROJECTS =
  process.env.REACT_APP_GET_COMPANY_PROJECTS || '/api/GetProjectsByCompany';

/**
 * Hook for å hente liste over offentlige selskaper
 */
export const useCompanies = (BusinessArea = null, page = 0, limit = 50) => {
  const filters = { BusinessArea, page, limit };

  return useQuery(
    QueryKeys.companies.list(filters),
    async () => {
      const params = new URLSearchParams();
      if (BusinessArea) params.append('BusinessArea', BusinessArea);
      params.append('offset', page * limit);
      params.append('limit', limit);

      const response = await axiosInstance.get(
        `${API_GET_COMPANIES}?${params}`
      );
      return response.data;
    },
    {
      staleTime: 300000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

/**
 * Optimalisert hook for å hente et enkelt selskap med ID
 * @param {number|string} id - Selskaps-ID
 * @param {boolean} includePrivate - Inkluder private data
 * @returns {Object} - React Query-resultat
 */
export const useCompany = (id, includePrivate = false) => {
  const queryClient = useQueryClient();

  return useQuery(
    QueryKeys.companies.detail(id),
    async () => {
      if (!id) return null;

      try {
        // OPTIMALISERING 1: Sjekk først om vi har data fra liste-queries
        const companiesListData = queryClient.getQueryData(
          QueryKeys.companies.list()
        );
        if (companiesListData?.companies) {
          const companyFromList = companiesListData.companies.find(
            (c) => c.id === Number(id)
          );
          if (companyFromList && !includePrivate) {
            // Plasser denne dataen i cachen mens vi fortsetter med fetchingen for å få fullstendige data
            // Dette gir umiddelbar UI-respons mens detaljene lastes
            queryClient.setQueryData(
              QueryKeys.companies.detail(id),
              companyFromList
            );
          }
        }

        const params = includePrivate ? '?includePrivate=true' : '';
        const response = await axiosInstance.get(
          `${API_GET_COMPANY}/${id}${params}`
        );
        return response.data;
      } catch (error) {
        // Fallback til lokal data hvis tilgjengelig (f.eks. for nyopprettede selskaper)
        const localData = sessionStorage.getItem(`company_${id}`);
        if (localData) {
          logger.log(`Bruker lokal data for selskap ${id}`);
          return JSON.parse(localData);
        }
        throw error;
      }
    },
    {
      enabled: !!id,
      staleTime: 300000, // 5 minutter - data er "fersk"
      cacheTime: 600000, // 10 minutter - beholdes i cache etter staleTime
      refetchOnWindowFocus: false, // Ikke last på nytt ved vindus-fokus
      refetchOnMount: false, // Ikke last på nytt ved montering
      retry: 5, // Gjør 5 forsøk
      retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 30000), // Eksponentiell backoff med maks 30 sekunder
      onError: (error) => {
        logger.error(`Kunne ikke hente selskap med ID ${id}:`, error);
      },
    }
  );
};

/**
 * Hook for å søke etter selskaper
 * @param {string} searchTerm - Søkeord
 * @param {string} BusinessArea - Filter på bransje
 * @param {number} page - Sidenummer (0-indeksert)
 * @param {number} limit - Antall resultater per side
 */
export const useCompanySearch = (
  searchTerm,
  BusinessArea = null,
  page = 0,
  limit = 50
) => {
  const filters = { BusinessArea, page, limit };

  return useQuery(
    QueryKeys.companies.search(searchTerm, filters),
    async () => {
      const params = new URLSearchParams();
      params.append('q', searchTerm);
      if (BusinessArea) params.append('BusinessArea', BusinessArea);
      params.append('offset', page * limit);
      params.append('limit', limit);

      const response = await axiosInstance.get(
        `/api/companies/search?${params}`
      );
      return response.data;
    },
    {
      enabled: searchTerm?.length >= 2, // Kun kjør ved minimum 2 tegn
      keepPreviousData: true,
      staleTime: 60000, // 1 minutt (kortere for søkeresultater)
      refetchOnWindowFocus: false,
    }
  );
};

/**
 * Hook for å hente prosjekter for et selskap
 */
export const useCompanyProjects = (
  companyId,
  includePrivate = false,
  page = 0,
  limit = 50
) => {
  const filters = { includePrivate, page, limit };

  return useQuery(
    QueryKeys.companies.projects(companyId, filters),
    async () => {
      if (!companyId) return { projects: [], total: 0 };

      try {
        const params = new URLSearchParams();
        if (includePrivate) params.append('includePrivate', 'true');
        params.append('offset', page * limit);
        params.append('limit', limit);

        const response = await axiosInstance.get(
          `${API_GET_COMPANY_PROJECTS}/${companyId}?${params}`
        );

        // Normaliser responsen for å sikre at vi alltid har et standardisert objektformat
        return {
          projects: response.data?.projects || [],
          total: response.data?.total || 0,
          limit: response.data?.limit || limit,
          offset: response.data?.offset || 0,
        };
      } catch (error) {
        logger.error(
          `Feil ved henting av prosjekter for selskap ${companyId}:`,
          error
        );
        // Returner et standardisert tomt objekt ved feil
        return {
          projects: [],
          total: 0,
          limit: limit,
          offset: page * limit,
        };
      }
    },
    {
      enabled: !!companyId,
      keepPreviousData: true,
      staleTime: 180000,
      refetchOnWindowFocus: false,
      retry: 1,
    }
  );
};

/**
 * Hook for å oppdatere selskapsinformasjon
 */
export const useUpdateCompany = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (companyData) => {
      const { id, ...updateData } = companyData;

      // Korrekt URL format basert på function.json route: UpdateCompany/{id}
      const response = await axiosInstance.post(
        `${API_UPDATE_COMPANY}/${id}`,
        updateData
      );
      return response.data;
    },
    {
      onSuccess: (data, variables) => {
        // Oppdater cachen
        if (variables.id) {
          queryClient.invalidateQueries(
            QueryKeys.companies.detail(variables.id)
          );
          queryClient.invalidateQueries(QueryKeys.companies.list());
        }
      },
    }
  );
};

/**
 * Hook for å oppdatere et prosjekts synlighetsinnstillinger
 */
export const useUpdateProjectVisibility = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ projectId, isPublic, companyId }) => {
      const response = await axiosInstance.post(
        `/api/projects/update/${projectId}`,
        { isPublic }
      );
      return { ...response.data, companyId };
    },
    {
      // Ved vellykket oppdatering, invalider relevante spørringer
      onSuccess: (data) => {
        queryClient.invalidateQueries(
          QueryKeys.companies.projects(data.companyId)
        );
      },
    }
  );
};
