import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useCreateProject } from '../../hooks/useProjects.js';
import './ProjectModal.css';
import {
  FaTimes,
  FaBuilding,
  FaImage,
  FaTasks,
  FaPlus,
  FaTrash,
} from 'react-icons/fa';

const CreateProjectForm = ({ companyId, onClose, onSuccess }) => {
  const [projectData, setProjectData] = useState({
    name: '',
    description: '',
    companyId: companyId,
    isPublic: true,
    businessArea: '',
    thumbnailUrl: '',
    initialTasks: [],
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showTaskSection, setShowTaskSection] = useState(false);
  const [newTask, setNewTask] = useState({
    name: '',
    start: new Date().toISOString().slice(0, 10),
    end: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10),
    progress: 0,
  });

  const createProject = useCreateProject();

  const validate = () => {
    const newErrors = {};
    if (!projectData.name.trim()) {
      newErrors.name = 'Project name is required';
    }

    if (projectData.thumbnailUrl && !isValidUrl(projectData.thumbnailUrl)) {
      newErrors.thumbnailUrl = 'Please enter a valid URL';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const isValidUrl = (url) => {
    if (!url) return true; // Empty URL is valid (not required)
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setProjectData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));

    // Clear error on change
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: undefined,
      }));
    }
  };

  const handleTaskChange = (e) => {
    const { name, value } = e.target;
    setNewTask((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const addTask = () => {
    if (!newTask.name.trim()) {
      setErrors((prev) => ({
        ...prev,
        taskName: 'Task name is required',
      }));
      return;
    }

    setProjectData((prev) => ({
      ...prev,
      initialTasks: [
        ...prev.initialTasks,
        { ...newTask, id: Date.now() }, // Temporary ID for UI purposes
      ],
    }));

    // Reset new task form
    setNewTask({
      name: '',
      start: new Date().toISOString().slice(0, 10),
      end: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
        .toISOString()
        .slice(0, 10),
      progress: 0,
    });

    // Clear any task-related errors
    if (errors.taskName) {
      setErrors((prev) => ({
        ...prev,
        taskName: undefined,
      }));
    }
  };

  const removeTask = (taskId) => {
    setProjectData((prev) => ({
      ...prev,
      initialTasks: prev.initialTasks.filter((task) => task.id !== taskId),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      return;
    }

    setIsSubmitting(true);

    try {
      // Use our hook to create the project, which will call our API
      const result = await createProject.mutateAsync(projectData);

      if (onSuccess) {
        onSuccess(result);
      }

      onClose();
    } catch (err) {
      console.error('Error creating project:', err);
      setErrors((prev) => ({
        ...prev,
        form: err.response?.data?.message || 'Failed to create project',
      }));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="project-form-container">
      <div className="project-form-header">
        <h2>Create New Project</h2>
        <button className="close-button" onClick={onClose}>
          <FaTimes />
        </button>
      </div>

      {errors.form && <div className="error-message">{errors.form}</div>}

      <form onSubmit={handleSubmit} className="project-form">
        <div className="form-group">
          <label htmlFor="name">Project Name*</label>
          <input
            type="text"
            id="name"
            name="name"
            value={projectData.name}
            onChange={handleChange}
            className={errors.name ? 'form-control error' : 'form-control'}
            placeholder="Enter project name"
          />
          {errors.name && <div className="error-text">{errors.name}</div>}
        </div>

        <div className="form-group">
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            name="description"
            value={projectData.description}
            onChange={handleChange}
            className="form-control"
            rows={4}
            placeholder="Describe your project"
          />
        </div>

        <div className="form-group">
          <label htmlFor="businessArea">
            <FaBuilding className="icon" /> Business Area
          </label>
          <input
            type="text"
            id="businessArea"
            name="businessArea"
            value={projectData.businessArea}
            onChange={handleChange}
            className="form-control"
            placeholder="E.g. Construction, IT, Energy"
          />
        </div>

        <div className="form-group">
          <label htmlFor="thumbnailUrl">
            <FaImage className="icon" /> Thumbnail URL
          </label>
          <input
            type="text"
            id="thumbnailUrl"
            name="thumbnailUrl"
            value={projectData.thumbnailUrl}
            onChange={handleChange}
            className={
              errors.thumbnailUrl ? 'form-control error' : 'form-control'
            }
            placeholder="https://example.com/image.jpg"
          />
          {errors.thumbnailUrl && (
            <div className="error-text">{errors.thumbnailUrl}</div>
          )}
        </div>

        <div className="form-group checkbox-group">
          <label className="checkbox-label">
            <input
              type="checkbox"
              name="isPublic"
              checked={projectData.isPublic}
              onChange={handleChange}
            />
            <span>Show in public gallery</span>
          </label>
        </div>

        {/* Initial Tasks Section */}
        <div className="tasks-section">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setShowTaskSection(!showTaskSection)}
          >
            <FaTasks />{' '}
            {showTaskSection ? 'Hide Initial Tasks' : 'Add Initial Tasks'}
          </button>

          {showTaskSection && (
            <div className="initial-tasks-container">
              <h3>Initial Tasks</h3>

              {/* Task input form */}
              <div className="task-form">
                <div className="form-group">
                  <label htmlFor="taskName">Task Name*</label>
                  <input
                    type="text"
                    id="taskName"
                    name="name"
                    value={newTask.name}
                    onChange={handleTaskChange}
                    className={
                      errors.taskName ? 'form-control error' : 'form-control'
                    }
                    placeholder="Enter task name"
                  />
                  {errors.taskName && (
                    <div className="error-text">{errors.taskName}</div>
                  )}
                </div>

                <div className="form-row">
                  <div className="form-group half-width">
                    <label htmlFor="taskStart">Start Date</label>
                    <input
                      type="date"
                      id="taskStart"
                      name="start"
                      value={newTask.start}
                      onChange={handleTaskChange}
                      className="form-control"
                    />
                  </div>

                  <div className="form-group half-width">
                    <label htmlFor="taskEnd">End Date</label>
                    <input
                      type="date"
                      id="taskEnd"
                      name="end"
                      value={newTask.end}
                      onChange={handleTaskChange}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="taskProgress">Progress (%)</label>
                  <input
                    type="number"
                    id="taskProgress"
                    name="progress"
                    value={newTask.progress}
                    onChange={handleTaskChange}
                    min="0"
                    max="100"
                    className="form-control"
                  />
                </div>

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={addTask}
                >
                  <FaPlus /> Add Task
                </button>
              </div>

              {/* Task list */}
              {projectData.initialTasks.length > 0 && (
                <div className="task-list">
                  <h4>Tasks ({projectData.initialTasks.length})</h4>
                  <ul>
                    {projectData.initialTasks.map((task) => (
                      <li key={task.id} className="task-item">
                        <div className="task-details">
                          <span className="task-name">{task.name}</span>
                          <span className="task-dates">
                            {new Date(task.start).toLocaleDateString()} -
                            {new Date(task.end).toLocaleDateString()}
                          </span>
                          <div className="task-progress">
                            <div
                              className="progress-bar"
                              style={{ width: `${task.progress}%` }}
                            ></div>
                            <span>{task.progress}%</span>
                          </div>
                        </div>
                        <button
                          type="button"
                          className="btn-icon"
                          onClick={() => removeTask(task.id)}
                        >
                          <FaTrash />
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="modal-actions">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onClose}
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Creating...' : 'Create Project'}
          </button>
        </div>
      </form>
    </div>
  );
};

CreateProjectForm.propTypes = {
  companyId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};

export default CreateProjectForm;
