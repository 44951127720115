import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'; // Add this import
import ReactMarkdown from 'react-markdown'; // Import ReactMarkdown
import backgroundPlancoo from '../../assets/backgroundPlancoo2.jpg';
import SoMePlancoo from '../../assets/SoMePlanCoo.jpg'; // Import the SoMePlancoo image
import Gantt2 from '../../assets/Gantt2.jpg'; // Import the Gantt2 image
import './LandingPage.css';
import logger from '../../utils/logger.js'; // Import logger
import SEOHelmet from '../SEO/SEOHelmet.js';

const LandingPage = () => {
  const { t, ready } = useTranslation();
  const rainContainerRef = useRef(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    comments: '',
  });
  const [formStatus, setFormStatus] = useState({
    submitting: false,
    success: false,
    error: null,
  });

  // Add new state for carousel
  const [currentSlide, setCurrentSlide] = useState(0);

  // Memoize feature slides to prevent unnecessary re-renders
  const featureSlides = useMemo(
    () => [
      {
        image: SoMePlancoo,
        title: t('Our Own Social Media'),
        description: t('social_media_description'),
      },
      {
        image: Gantt2,
        title: t('Project Planning'),
        description: t('project_planning_description'),
      },
      {
        image: backgroundPlancoo, // Using existing image as third slide example
        title: t('Team Collaboration'),
        description: t('team_collaboration_description'),
      },
    ],
    [t]
  ); // Only recreate when translations change

  const nextSlide = () => {
    setCurrentSlide((prev) =>
      prev === featureSlides.length - 1 ? 0 : prev + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prev) =>
      prev === 0 ? featureSlides.length - 1 : prev - 1
    );
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  // Memoize the preloadImages function
  const preloadImages = useCallback(() => {
    featureSlides.forEach((slide) => {
      const img = new Image();
      img.src = slide.image;
    });
  }, [featureSlides]); // Depends on featureSlides

  // 2. useEffect-hooken må også defineres her
  useEffect(() => {
    // Bruk en betingelse inne i hooken i stedet for å kalle hooken betinget
    if (!rainContainerRef.current || !ready) return;

    // Sett en fast høyde på regn-containeren før regndråpene legges til
    const container = rainContainerRef.current;
    container.style.height = '100%';

    // Kjør regn-genereringen etter en kort forsinkelse for å unngå layout-skift
    const timer = setTimeout(() => {
      const containerWidth = window.innerWidth;

      // Færre dråper for den nye stilen
      const dropCount = Math.floor(containerWidth / 100); // 1 dråpe per 100px

      // Fjern eventuelle eksisterende dråper
      container.innerHTML = '';

      // Opprett nye regndråper
      for (let i = 0; i < dropCount; i++) {
        const drop = document.createElement('div');
        drop.classList.add('rain');

        // Tilfeldige verdier for hver dråpe
        const leftPos = Math.random() * 100; // Tilfeldig horisontal posisjon
        const delay = Math.random() * 5; // Større variasjon i forsinkelse

        // Sett stilen for dråpen
        drop.style.left = `${leftPos}%`;
        drop.style.animationDelay = `${delay}s`;

        container.appendChild(drop);
      }
    }, 100);

    // Legg til en funksjon for å oppdatere regnet når vindusstørrelsen endres
    const handleResize = () => {
      if (container) {
        // Kjør regngeneratoren på nytt ved størrelseendring
        const newWidth = window.innerWidth;
        const newDropCount = Math.floor(newWidth / 100);

        // Tøm containeren
        container.innerHTML = '';

        // Opprett nye regndråper
        for (let i = 0; i < newDropCount; i++) {
          const drop = document.createElement('div');
          drop.classList.add('rain');

          const leftPos = Math.random() * 100;
          const delay = Math.random() * 5;

          drop.style.left = `${leftPos}%`;
          drop.style.animationDelay = `${delay}s`;

          container.appendChild(drop);
        }
      }
    };

    // Legg til event listener for vindusstørrelseendringer
    window.addEventListener('resize', handleResize);

    // Rydde opp når komponenten unmounts
    return () => {
      clearTimeout(timer);
      window.removeEventListener('resize', handleResize);
    };
  }, [ready]); // Legg til ready som en avhengighet

  useEffect(() => {
    preloadImages();
  }, [preloadImages]); // Now properly includes the dependency

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Valider input
    if (!formData.name || !formData.email) {
      setFormStatus({
        submitting: false,
        success: false,
        error: t('newsletter_form_validation_error'),
      });
      return;
    }

    setFormStatus({
      submitting: true,
      success: false,
      error: null,
    });

    // Kjør en sjekk for om vi er i utviklingsmiljøet
    const isDevelopment = process.env.NODE_ENV === 'development';
    const apiBaseUrl = isDevelopment
      ? 'http://localhost:7071' // Standard Azure Functions-port lokalt
      : '';

    try {
      const response = await fetch(`${apiBaseUrl}/api/newsletter`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        // Håndter ulike HTTP-feilkoder
        if (response.status === 404) {
          throw new Error(
            'Newsletter API endpoint not found. Please check the API configuration.'
          );
        }

        // Forsøk å lese feilmeldingen fra responsen
        const errorData = await response.text();
        console.error('Newsletter API error response:', errorData);

        try {
          // Se om responsen er JSON
          const errorJson = JSON.parse(errorData);
          throw new Error(
            errorJson.message || 'An error occurred during submission'
          );
        } catch (e) {
          // Hvis ikke JSON, bruk rå tekst med begrensning
          const maxLength = 100;
          const truncatedError =
            errorData.length > maxLength
              ? errorData.substring(0, maxLength) + '...'
              : errorData;
          throw new Error(`Newsletter submission error: ${truncatedError}`);
        }
      }

      const data = await response.json();
      logger.log('API response:', data);

      // Håndter vellykket innsending og bruk meldingen fra API-et
      setFormStatus({
        submitting: false,
        success: true,
        error: null,
        message: data.message || t('newsletter_success'),
      });

      // Reset formdata
      setFormData({
        name: '',
        email: '',
        company: '',
        comments: '',
      });

      // Vis suksessmelding i 3 sekunder
      setTimeout(() => {
        setFormStatus((prev) => ({ ...prev, success: false }));
      }, 3000);
    } catch (error) {
      console.error('Newsletter submission error:', error);

      setFormStatus({
        submitting: false,
        success: false,
        error: error.message || 'An error occurred during submission',
      });
    }
  };

  // 3. Betinget rendering etter alle hooks er definert
  if (!ready) {
    return (
      <div className="wrapper skeleton-wrapper">
        <SEOHelmet
          title="PlanCoo - Prosjektstyringsverktøy"
          description="Moderne prosjektstyring med Gantt-diagrammer, oppgavestyring og samarbeid."
        />
        <div className="landing-container skeleton">
          <div className="landing-logo skeleton-box"></div>
          <div className="landing-mainmessage">
            <div className="skeleton-line"></div>
            <div className="skeleton-line"></div>
            <div className="skeleton-line"></div>
            <div className="skeleton-line"></div>
          </div>
          <div className="landing-image skeleton-box"></div>
        </div>
      </div>
    );
  }

  // 4. Hovedretur etter hooks og betingelser
  return (
    <>
      <SEOHelmet
        title="PlanCoo - Prosjektstyringsverktøy for bedrifter og individer"
        description="Moderne prosjektstyring med Gantt-diagrammer, oppgavestyring og samarbeid. Effektiviser prosjektene dine med PlanCoo."
        image="https://www.plancoo.com/static/media/logo.png"
        url="https://www.plancoo.com"
      />
      <div className="wrapper">
        {/* Rain effect overlay */}
        <div className="rain-container" ref={rainContainerRef}></div>

        <div className="landing-container">
          <div className="landing-logo"></div>
          <div className="landing-mainmessage">
            <div className="hero-text-container">
              <h1 className="hero-title">
                {t('hero_title', 'The first')}{' '}
                <span className="highlight">
                  {t('hero_title_highlight', 'social media')}
                </span>{' '}
                {t('hero_title_end', 'for project management')}
              </h1>
              <p className="hero-subtitle">
                {t(
                  'hero_subtitle',
                  'Our mission: to make collaboration effortless.'
                )}
              </p>
              <div className="hero-description">
                {t('hero_description', 'With')}{' '}
                <span className="brand-name">PlanCoo</span>,{' '}
                {t('hero_description_middle', 'you')}{' '}
                <span className="highlight">{t('connect', 'connect')}</span>,{' '}
                <span className="highlight">{t('share', 'share')}</span>,{' '}
                {t('and', 'align')}{' '}
                <span className="highlight">{t('align', 'align')}</span>
                {t(
                  'hero_description_end',
                  '—all in one place, with no extra accounts or scattered communication.'
                )}
              </div>
              <div className="hero-cta">
                <Link to="/subscriptions" className="btn hero-btn">
                  {t('get_started', 'Get Started')}
                </Link>
              </div>
            </div>
          </div>
          <div className="landing-image">
            <img
              src={backgroundPlancoo}
              alt="backgroundPlancoo"
              width="100%"
              height="auto"
              style={{ aspectRatio: '16/9' }} // Sett riktig aspect ratio basert på ditt bilde
            />
          </div>
        </div>

        {/* Feature Carousel Section */}
        <div className="feature-carousel-container">
          <h2 className="carousel-title">{t('Features')}</h2>

          <div className="carousel-wrapper">
            <button
              className="carousel-arrow carousel-arrow-left"
              onClick={prevSlide}
              aria-label="Previous feature"
            >
              &#10094;
            </button>

            <div className="carousel-content">
              {featureSlides.map((slide, index) => (
                <div
                  key={index}
                  className={`carousel-slide ${index === currentSlide ? 'active' : ''}`}
                  style={{
                    transform: `translateX(${100 * (index - currentSlide)}%)`,
                  }}
                >
                  <div className="carousel-image-wrapper">
                    <img
                      src={slide.image}
                      alt={slide.title}
                      className="carousel-image"
                      loading="lazy"
                    />
                  </div>
                  <div className="carousel-text">
                    <h3 className="carousel-slide-title">{slide.title}</h3>
                    <div className="carousel-description">
                      <ReactMarkdown>{slide.description}</ReactMarkdown>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <button
              className="carousel-arrow carousel-arrow-right"
              onClick={nextSlide}
              aria-label="Next feature"
            >
              &#10095;
            </button>
          </div>

          <div className="carousel-dots">
            {featureSlides.map((_, index) => (
              <button
                key={index}
                className={`carousel-dot ${index === currentSlide ? 'active' : ''}`}
                onClick={() => goToSlide(index)}
                aria-label={`Go to feature ${index + 1}`}
              />
            ))}
          </div>
        </div>

        <div className="landing-midtext">
          <div className="midtext-heading">{t('midtext_heading')}</div>
          <div className="midtext-paragraph">{t('midtext_paragraph')}</div>

          {/* Newsletter Subscription Form */}
          <div className="newsletter-form-container">
            <h3 className="newsletter-title">{t('newsletter_title')}</h3>

            {formStatus.success ? (
              <div className="newsletter-success">
                {t('newsletter_success')}
              </div>
            ) : (
              <form className="newsletter-form" onSubmit={handleSubmit}>
                {/* First row: Name, Email, Company */}
                <div className="form-row-1">
                  <div className="form-group">
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      placeholder={t('name_placeholder')}
                      required
                      maxLength={100} // Begrens lengden
                      pattern="[A-Za-z0-9 ]+" // Tillat bare alfanumeriske tegn og mellomrom
                      disabled={formStatus.submitting}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      placeholder={t('email_placeholder')}
                      required
                      maxLength={100} // Begrens lengden
                      pattern="[^@]+@[^@]+\.[^@]+" // Enkel e-postvalidering
                      disabled={formStatus.submitting}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      name="company"
                      value={formData.company}
                      onChange={handleInputChange}
                      placeholder={t('company_placeholder')}
                      disabled={formStatus.submitting}
                    />
                  </div>
                </div>

                {/* Second row: Comments, Button */}
                <div className="form-row-2">
                  <div className="form-group">
                    <textarea
                      name="comments"
                      value={formData.comments}
                      onChange={handleInputChange}
                      placeholder={t('comments_placeholder')}
                      disabled={formStatus.submitting}
                    ></textarea>
                  </div>

                  <button
                    type="submit"
                    className="newsletter-submit-btn"
                    disabled={formStatus.submitting}
                  >
                    {formStatus.submitting
                      ? t('submitting')
                      : t('subscribe_button')}
                  </button>
                </div>

                {formStatus.error && (
                  <div className="newsletter-error">{formStatus.error}</div>
                )}
              </form>
            )}
            <div className="midtext-sponsors-cta">{t('sponsors_cta')}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
