import React from 'react';
import './Spinner.css';
import PropTypes from 'prop-types';

/**
 * En enkel Spinner-komponent for inline lasting av innhold
 * @param {Object} props - Komponentens props
 * @param {string} props.text - Tekst som vises under spinneren
 * @param {string} props.size - Størrelse på spinneren ('sm', 'md', 'lg')
 */
const Spinner = ({ text, size = 'md' }) => {
  return (
    <div className={`spinner-container spinner-${size}`}>
      <div className="spinner-circle"></div>
      {text && <div className="spinner-text">{text}</div>}
    </div>
  );
};

// Legg til prop-types for å unngå ESLint-advarsler
Spinner.propTypes = {
  text: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

export default Spinner;
