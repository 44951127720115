import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import NavSteps from '../Navigation/NavSteps.js';
import './CompanyLayout.css';

/**
 * A layout component for company-related pages that integrates navigation steps
 * Following Azure best practices for component architecture and resource efficiency
 *
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Child components to render in the layout
 * @param {string} props.title - Page title (optional)
 * @param {string} props.activeStep - Current active step for navigation (optional, defaults to "company")
 * @param {boolean} props.hideSidebarOnMobile - Whether to hide the sidebar on mobile devices
 */
const CompanyLayout = ({
  children,
  title,
  activeStep = 'company', // Default to "company" step
  hideSidebarOnMobile = false,
}) => {
  // Apply page title
  useEffect(() => {
    if (title) {
      document.title = `${title} | PlanCoo`;
    }
    return () => {
      document.title = 'PlanCoo';
    };
  }, [title]);

  return (
    <div
      className={`company-layout ${hideSidebarOnMobile ? 'mobile-no-sidebar' : ''}`}
    >
      {/* NavSteps component - now part of the layout */}
      <NavSteps activeStep={activeStep} />

      <div className="company-content-wrapper">
        <div className="company-content">
          <div className="company-content-container mobile-scrollable">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

CompanyLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  activeStep: PropTypes.string, // Add prop type for activeStep
  hideSidebarOnMobile: PropTypes.bool,
};

export default CompanyLayout;
